import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import clsx from 'clsx';
import { Box, Button, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { currencyFormat } from '../utils/currencyFormat';
// import { paymentData } from './PaymentCardModal';
import { initPaymentCardSecond } from '../redux/slices/user.slice';
const TotalCart = ({ total, supportCount }) => {
  const {
    getTypePaymentListState: { data: paymentData },
  } = useSelector((state) => state.app);
  const { cart } = useSelector((state) => state.user);
  const [valuePayment, setValuePayment] = React.useState(null);

  useEffect(() => {
    if (paymentData?.length >= 1) {
      setValuePayment(paymentData?.[0]?.id);
    }
  }, [paymentData]);
  useEffect(() => {
    paymentData?.filter((payItem) => total >= payItem?.minSum);
    if (paymentData?.length >= 1) {
      setValuePayment(paymentData?.[0]?.id);
    } else {
      setValuePayment(null);
    }
  }, [cart]);

  const dispatch = useDispatch();
  const onSubmit = () => {
    if (valuePayment) {
      const totalPrice = cart?.map((cartItem) => cartItem?.price * cartItem?.count).reduce((partialSum, a) => partialSum + a, 0);
      const supportSum = supportCount * 50;
      const selectPayment = paymentData?.find((pay) => pay?.id == valuePayment);
      dispatch(initPaymentCardSecond({ price: totalPrice, priceSupport: supportSum, type: selectPayment?.value, typePay: selectPayment?.type, typeId: selectPayment?.id }));
    }
  };
  const handleChange = (event) => {
    setValuePayment(event.target.value);
  };
  return (
    <>
      <Box sx={{ backgroundColor: '#fff', borderRadius: '8px' }}>
        <Box component={'h2'} sx={{ fontWeight: '500 !important', margin: 0, padding: '20px', borderBottom: '1px solid rgba(0,0,0,0.2)' }}>
          Счет
        </Box>

        <Box sx={{ padding: '20px', borderBottom: '1px solid rgba(0,0,0,0.2)' }}>
          <RadioGroup
            sx={{ flexDirection: 'row', display: 'flex', margin: { xs: '0 auto', mob: '0' }, flexWrap: 'wrap', rowGap: '20px', columnGap: '20px', justifyContent: 'start' }}
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={valuePayment}
            onChange={handleChange}>
            {paymentData
              ?.filter((payItem) => total >= payItem?.minSum)
              ?.map((item) => (
                <FormControlLabel
                  sx={{
                    position: 'relative',
                    width: 'min-content',

                    margin: 0,
                    marginBottom: '24px',
                    borderRadius: '6px',
                    background: item?.id == valuePayment ? '#FDDA5E' : 'rgba(0,0,0,0.05)',
                    padding: '5px 10px 5px 10px',
                    position: 'relative',
                    '& img': { userSelect: 'none', pointerEvents: 'none', boxSizing: 'border-box', p: '0px', width: '80px', height: '60px', objectFit: 'contain', display: 'block' },
                  }}
                  value={item?.id}
                  control={
                    <Radio
                      sx={{
                        display: 'none',
                        transform: 'scale(0.8)',
                        padding: '0px',
                        position: 'absolute',
                        right: '-1px',
                        top: '1px',
                        '& svg': {
                          color: item?.id == valuePayment ? '#FDDA5E' : 'rgba(0,0,0,0.05)',
                        },
                      }}
                    />
                  }
                  label={
                    <>
                      <img src={item?.image} style={{}} />
                      <Box style={{ userSelect: 'none', textAlign: 'center', marginTop: '5px', fontSize: '12px' }}>{item?.name}</Box>
                      <Box
                        sx={{
                          userSelect: 'none',
                          textAlign: 'center',
                          fontSize: '12px',
                          position: 'absolute',
                          bottom: '-24px',
                          left: '50%',
                          borderRadius: '10px',
                          padding: '0 6px',
                          transform: 'translateX(-50%)',
                          width: 'min-content',
                          whiteSpace: 'nowrap',
                          background: item?.id == valuePayment ? '#FDDA5E' : 'rgba(0,0,0,0.05)',
                        }}>{`${item?.commission}%`}</Box>
                    </>
                  }
                />
              ))}
          </RadioGroup>
        </Box>
        <Box sx={{ padding: '20px', borderBottom: '1px solid rgba(0,0,0,0.2)' }}>
          {' '}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ fontSize: '18px' }}>Сумма (поступит на счет)</Box>
            <Box sx={{ fontSize: '18px' }}>{currencyFormat(total)}</Box>
          </Box>{' '}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '15px' }}>
            <Box sx={{ fontSize: '18px' }}>Комиссия</Box>
            <Box sx={{ fontSize: '18px' }}>{`${paymentData?.find((pay) => pay?.id == valuePayment)?.commission ?? 0} %`}</Box>
          </Box>
        </Box>
        <Box sx={{ padding: '20px', paddingBottom: '30px' }}>
          {' '}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ fontSize: '20px', fontWeight: '600 !important' }}>ИТОГО К ОПЛАТЕ</Box>
            <Box sx={{ fontSize: '20px', fontWeight: '600 !important' }}>{currencyFormat(parseFloat(total) + parseFloat((parseFloat(paymentData?.find((pay) => pay?.id == valuePayment)?.commission ?? 0) / 100) * parseFloat(total)))}</Box>
          </Box>{' '}
        </Box>
        <Button color={'success'} size="large" variant="contained" sx={{ borderRadius: '8px', width: '100%', padding: '12px', fontSize: '18px' }} onClick={onSubmit}>
          Оплатить
        </Button>
      </Box>
    </>
  );
};

export default TotalCart;
