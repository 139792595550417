import { Helmet } from 'react-helmet';

export const ru = {
  swtichTypeDonateLabel: 'Буст',
  aboutService: 'О сервисе',
  main: 'Главная',
  mainPage: 'Главная страница',
  rating: 'Рейтинг',
  ratings: 'Рейтинги',
  instruction: 'Инструкция',
  faq: 'FAQ',
  reviews: 'Отзывы',
  mainPage: 'Главная страница',
  choicePack: '  Выберите пак',
  choiceNickText: 'Сменить ник на сайте можно только один раз',
  hideInTop: 'Скрывать из ТОП 100',
  showInTop: 'Показывать в ТОП 100',
  lastDonates: 'Последние донаты',
  account: 'Личный кабинет',
  top100: 'Топ 100',
  forMonth: 'За месяц',
  usSupport: 'Булочки',
  top100MonthText: 'В таблице отображены пользователи с наибольшим суммарным донатом игровой валюты за месяц. Рейтинг обновляется в режиме реального времени. В настройках аккаунта вы можете скрыть себя из рейтинга.',
  top100Text: 'В таблице отображены ТОП 100 пользователей с наибольшим суммарным донатом игровой валюты за всё время пользования сайтом. Рейтинг обновляется в режиме реального времени. В настройках аккаунта вы можете скрыть себя из рейтинга.',
  hideInSupport: 'Скрывать из списка поддержки',
  donateSuccess: (
    <>
      {' '}
      Донат успешно отправлен, ожидайте его поступления в игру. <br /> Чек операции отправлен вам на почту.
      <a href="https://donate-gold.ru/reviews" class="link" style={{ display: 'block', margin: '0 auto', marginTop: '30px' }}>
        Оставьте, пожалуйста, отзыв!
      </a>
    </>
  ),
  donateSuccessIden: (
    <>
      <p style={{ margin: 0, fontSize: '14px' }}>
        Ваш донат отправлен в обработку сервера. <br /> Примерное время доставки составляет 5-15 минут.
        <br /> Ожидайте печатей в игру! И не переживайте, если доставка задержится, всё поступит!
        <a href="https://donate-gold.ru/reviews" class="link" style={{ display: 'block', margin: '0 auto', marginTop: '30px' }}>
          Оставьте, пожалуйста, отзыв!
        </a>
        &nbsp;❤️
      </p>
    </>
  ),
  showInSupport: 'Показывать в списке поддержки',
  yourInRating: 'Ваше место в рейтинге: ',
  yourCanHideNick: 'Вы можете скрыть себя из рейтингов ТОП 100 и списка поддержки.',
  areYourChoiceNick: 'Вы уже меняли свой ник',
  guarantee: 'Гарантии',
  support: 'Поддержка',
  listGame: 'Список игр',
  cabinet: 'Кабинет',
  accountNotFound: (
    <>
      Аккаунт не найден. <br />
      Проверьте ID или напишите в поддержку
    </>
  ),
  enterYourId: 'Введите ваш ID в игре',
  promocode1Title: 'Genshin Impact',
  promocode1Subtitle: 'Крылья звездного праздника №7',
  promocode1Text: 'Срок действия кода активации из набора №8 истекает 31 мая 2025 г. 23:59 (UTC+8)',

  promocode2Title: 'Gold Fish',
  promocode2Subtitle: 'Одна попытка в день',
  promocode2Text: (
    <>
      {' '}
      Ежедневно в случайное время приплывает 3 золотых рыбки, у каждой из них есть 100 ₽. <br /> Испытай свою удачу и попробуй поймать одну!
    </>
  ),
  promocode1SuccessTitle: 'Ура! Поймана! На ваш счет зачисленно 100 ₽!',
  promocode1SuccessText: 'Обновите страницу, чтобы сумма появилась.',
  promocode1ErrorTitle: 'Увы, сегодня вам не повезло. Испытайте удачу завтра!',
  promocode1ErrorText: 'Новые с будут позже.',
  promocode1Btn: ' Мне повезет',
  idInGame: 'ID в игре',
  yourId: 'Ваш ID:',
  idNotFound: 'ID не найден',
  countPackages: 'Количество печатей',
  order: 'Заказать',
  buy: 'Купить',
  slider11: 'Доступна Identity V 2.0 - Новая глава истории!',
  slider12: 'Репортер Алиса пребывает в Особняк',
  slider21: 'Обновление 3.6. первая фаза уже в игре!',
  slider22: 'Нилу и Нахида',
  slider31: 'Эпический скин Бэйна «Осквернитель душ» уже доступен',
  slider32: '19 по 25 апреля действует скидка 30%',
  slider41: 'Тематический игровой процесс BE@RBRICK',
  slider42: 'Встречайте стильных приятелей!',
  insufficientFunds: 'Недостаточно средств',
  instructionContent: (
    <>
      <iframe style={{ width: '100%', height: '600px' }} src="https://vk.com/video_ext.php?oid=-217542887&id=456239022&hash=d53fb04986567dec&hd=4" width="1920" height="1080" allow="autoplay; encrypted-media; fullscreen; picture-in-picture;" frameborder="0" allowfullscreen></iframe>
    </>
  ),
  termsOfPageText: (
    <>
      Данные правила регламентируют условия отношений между пользователем и сервисом сайта{' '}
      <a href="https://donate-gold.ru" class="link">
        https://donate-gold.ru
      </a>
      . Используя данный сайт вы автоматически соглашаетесь с данным Пользовательским Соглашением.
      <br />
      <br />
      <b>1. Термины</b>
      <br />
      Сервис (мы, наш) - вебсайт{' '}
      <a href="https://donate-gold.ru" class="link">
        https://donate-gold.ru
      </a>{' '}
      (Donate Gold). Пользователь (вы, ваш) - лицо, использующее сайт Donate Gold. Стороны - сервис и пользователь вместе, и по отдельности - сторона. Лицевой счет - виртуальный счет пользователя сайта Donate Gold. Игровая валюта - общее название виртуальной валюты, которая используется для
      приобретения виртуальных товаров в различных мобильных и компьютерных играх. Игровой аккаунт - личный игровой аккаунт пользователя соответствующей мобильной или компьютерной игры, с уникальным идентификатором (ID).
      <br />
      <br />
      <b>2. Предмет Соглашения</b>
      <br />
      Сервис предоставляет услуги пополнения игровой валюты игровых аккаунтов пользователей. Для обеспечения услуги пополнения игровой валюты пользователь списывает со своего лицевого счета стоимость игровой валюты в рублях, самостоятельно выбирая игру и номинал игровой валюты. Процедура пополнения
      игровой валюты проходит в автоматическом режиме через API разработчика соответствующей игры. Для пополнения лицевого счета, пользователь производит оплату в рублях с последующим зачислением их на баланс лицевого счета. Пополнение лицевого счета и пополнение игровой валютой в игре является
      окончательным и неизменным, денежные средства и игровая валюта не могут быть возвращены пользователю.
      <br />
      <br />
      <b>3. Права и обязанности сторон</b>
      <br />
      Пользователь обязан все действия на сайте Donate Gold проводить самостоятельно и для выполнения прямых целей сервиса. Пользователю запрещается обходить технические ограничения сервиса, копировать, декомпилировать или дизассемблировать сайт Donate Gold и совершать действия, направленные на
      изменение функционирования и работоспособности сайта. Пользователь вправе получать полную и актуальную информацию об услугах сервиса на сайте. Сервис обязуется предоставлять пользователю заявленные услуги качественно и в полном объеме. Стороны обязуются самостоятельно обеспечивать
      конфиденциальность сведений и информации, необходимых для доступа к сервису (логин и пароль), и не допускать разглашения такой информации.
      <br />
      <br />
      <b>4. Отказ от ответственности</b>
      <br />
      Если пользователь ввёл неправильные данные для пополнения игровой валюты своего игрового счета (ID - идентификационный номер игрока), после процедуры пополнения - возврат средств невозможен. Сервис не несёт ответственность за убытки пользователя, которые возникли в результате неправомерных
      действий от третьих лиц, в том числе связанных с неправомерным доступом к учетной записи пользователя. Сервис не несёт ответственности за пополнение игровой валюты на игровой аккаунт пользователя, который был украден, утерян или использован не по назначению без разрешения пользователя.
      Программно-аппаратные ошибки сервиса, возникшие при использовании сервиса пользователем и приведшие к затруднению оказания услуги, являются обстоятельствами непреодолимой силы и снимают ответственность с сервиса за неисполнение обязательств.
      <br />
      <br />
      <b>5. Персональные данные</b>
      <br />
      Принимая данное Соглашение, пользователь соглашается на обработку информации, в том числе персональных данных пользователя, предоставленных при использовании сервиса, для целей исполнения предмета соглашения. Обработка персональных данных — любое действие (операция) или совокупность действий
      (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление,
      доступ), обезличивание, блокирование, удаление, уничтожение персональных данных. Пользователь вправе отозвать согласие на обработку персональных данных, направив письмо на e-mail адрес, указанный на сайте Donate Gold.
      <br />
      <br />
      <b>6. Другие Соглашения</b>
      <br />
      Сервис не является правообладателем игровой валюты представленных игр, не определяет порядок их использования и функционирования в игре. Данное Пользовательское соглашения включает в себя ссылки отдельных Пользовательских Соглашений разработчиков игр. Пользователь так же выражает свое согласие
      с ними при пополнении игровой валюты соответствующей игры.
      <br />
      Identity V -{' '}
      <a href=" https://game.longeplay.com.tw/member/complete_agreement" class="link">
        https://game.longeplay.com.tw/member/complete_agreement
      </a>{' '}
      <br />
      <br />
      <b>7. Куки (cookies)</b>
      <br />
      Мы используем файлы cookies на нашем сайте. Используя сайт Donate Gold, вы соглашаетесь использовать файлы cookies в соответствии с нашей политикой конфиденциальности.
      <br />
      <br />
      <b>8. Разрешение споров</b>
      <br />
      Стороны будут стремиться решать все споры и разногласия путем переговоров по электронной почте. Если стороны не пришли к соглашению, дальнейшее решение подлежит разрешению в судебном порядке по месту нахождения владельца сервиса.
      <br />
      <br />
      <b>9. Изменения условий соглашения</b>
      <br />
      Мы вправе в одностороннем порядке изменять условия данного пользовательского соглашения без уведомления пользователя. Актуальная версия пользовательского соглашения расположена на{' '}
      <a href="https://donate-gold.ru/rules" class="link">
        {' '}
        данной{' '}
      </a>{' '}
      странице.
      <br />
      <br />
    </>
  ),
  totalPayable: 'Итого к оплате',
  nick: 'Ник',
  pay: 'Оплатить',
  date: 'Дата',
  removeAvatar: 'Удалить аватар',
  numPayment: 'Номер платежа',
  game: 'Игра',
  writeToUs: 'напишите нам',
  forSecurityText: 'В целях безопасности информация о ваших донатах отображается за последние три дня. Для получения архива донатов',
  ifErrorText:
    'Если возникла ошибка отправки доната, и средства у вас списались, то их возврат вам придет через 3 минуты. Просто отправьте донат ещё раз. В некоторых играх статусы могут обновляться с задержкой, например в Genshin Impact. Фактически донат вам уже может прийти, а на сайте будет статус "выполняется". В течение некоторого времени он обновится.',
  sendError: 'Ошибка отправки',
  writeToUs: 'выполняется возврат',
  avatar: 'Аватар',
  nick: 'Ник',
  resetPassword: 'Сброс пароля',
  settingAccount: 'Настройка аккаунта',
  nickOnSite: 'Никнейм на сайте',
  installAvatarText: 'Для установки аватара нажмите на него',
  resetThePassword: 'Сбросить пароль',
  paymentNumber: 'Номер платежа',
  userNotExist: 'Такого пользователя не существует',
  getNewPassowordText: 'Чтобы получить новый пароль, введи свой ник на сайте и email регистрации',
  newPromocodeText: 'Новые реферальные коды будут позже.',
  activeInGameText: 'Активируйте его в игре или официальном сайте.',
  pack: '',
  server: 'Сервер',
  status: 'Статус',
  supportListText: 'Булочки с корицей нас поддерживающие. Вы автоматически попадаете в этот список, если отправили денеженое сердечко при оплате. Мы вас благодарим!',
  rulesText: (
    <>
      Данные правила регламентируют условия отношений между пользователем и сервисом сайта{' '}
      <a href="https://donate-gold.ru" class="link">
        https://donate-gold.ru
      </a>
      . Используя данный сайт вы автоматически соглашаетесь с данным Пользовательским Соглашением.
      <br />
      <br />
      <b>1. Термины</b>
      <br />
      Сервис (мы, наш) - вебсайт{' '}
      <a href="https://donate-gold.ru" class="link">
        https://donate-gold.ru
      </a>{' '}
      (Donate Gold). Пользователь (вы, ваш) - лицо, использующее сайт Donate Gold. Стороны - сервис и пользователь вместе, и по отдельности - сторона. Лицевой счет - виртуальный счет пользователя сайта Donate Gold. Игровая валюта - общее название виртуальной валюты, которая используется для
      приобретения виртуальных товаров в различных мобильных и компьютерных играх. Игровой аккаунт - личный игровой аккаунт пользователя соответствующей мобильной или компьютерной игры, с уникальным идентификатором (ID).
      <br />
      <br />
      <b>2. Предмет Соглашения</b>
      <br />
      Сервис предоставляет услуги пополнения игровой валюты игровых аккаунтов пользователей. Для обеспечения услуги пополнения игровой валюты пользователь списывает со своего лицевого счета стоимость игровой валюты в рублях, самостоятельно выбирая игру и номинал игровой валюты. Процедура пополнения
      игровой валюты проходит в автоматическом режиме через API разработчика соответствующей игры. Для пополнения лицевого счета, пользователь производит оплату в рублях с последующим зачислением их на баланс лицевого счета. Пополнение лицевого счета и пополнение игровой валютой в игре является
      окончательным и неизменным, денежные средства и игровая валюта не могут быть возвращены пользователю.
      <br />
      <br />
      <b>3. Права и обязанности сторон</b>
      <br />
      Пользователь обязан все действия на сайте Donate Gold проводить самостоятельно и для выполнения прямых целей сервиса. Пользователю запрещается обходить технические ограничения сервиса, копировать, декомпилировать или дизассемблировать сайт Donate Gold и совершать действия, направленные на
      изменение функционирования и работоспособности сайта. Пользователь вправе получать полную и актуальную информацию об услугах сервиса на сайте. Сервис обязуется предоставлять пользователю заявленные услуги качественно и в полном объеме. Стороны обязуются самостоятельно обеспечивать
      конфиденциальность сведений и информации, необходимых для доступа к сервису (логин и пароль), и не допускать разглашения такой информации.
      <br />
      <br />
      <b>4. Отказ от ответственности</b>
      <br />
      Если пользователь ввёл неправильные данные для пополнения игровой валюты своего игрового счета (ID - идентификационный номер игрока), после процедуры пополнения - возврат средств невозможен. Сервис не несёт ответственность за убытки пользователя, которые возникли в результате неправомерных
      действий от третьих лиц, в том числе связанных с неправомерным доступом к учетной записи пользователя. Сервис не несёт ответственности за пополнение игровой валюты на игровой аккаунт пользователя, который был украден, утерян или использован не по назначению без разрешения пользователя.
      Программно-аппаратные ошибки сервиса, возникшие при использовании сервиса пользователем и приведшие к затруднению оказания услуги, являются обстоятельствами непреодолимой силы и снимают ответственность с сервиса за неисполнение обязательств.
      <br />
      <br />
      <b>5. Персональные данные</b>
      <br />
      Принимая данное Соглашение, пользователь соглашается на обработку информации, в том числе персональных данных пользователя, предоставленных при использовании сервиса, для целей исполнения предмета соглашения. Обработка персональных данных — любое действие (операция) или совокупность действий
      (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление,
      доступ), обезличивание, блокирование, удаление, уничтожение персональных данных. Пользователь вправе отозвать согласие на обработку персональных данных, направив письмо на e-mail адрес, указанный на сайте Donate Gold.
      <br />
      <br />
      <b>6. Другие Соглашения</b>
      <br />
      Сервис не является правообладателем игровой валюты представленных игр, не определяет порядок их использования и функционирования в игре. Данное Пользовательское соглашения включает в себя ссылки отдельных Пользовательских Соглашений разработчиков игр. Пользователь так же выражает свое согласие
      с ними при пополнении игровой валюты соответствующей игры.
      <br />
      Identity V -{' '}
      <a href=" https://game.longeplay.com.tw/member/complete_agreement" class="link">
        https://game.longeplay.com.tw/member/complete_agreement
      </a>{' '}
      <br />
      <br />
      <b>7. Куки (cookies)</b>
      <br />
      Мы используем файлы cookies на нашем сайте. Используя сайт Donate Gold, вы соглашаетесь использовать файлы cookies в соответствии с нашей политикой конфиденциальности.
      <br />
      <br />
      <b>8. Разрешение споров</b>
      <br />
      Стороны будут стремиться решать все споры и разногласия путем переговоров по электронной почте. Если стороны не пришли к соглашению, дальнейшее решение подлежит разрешению в судебном порядке по месту нахождения владельца сервиса.
      <br />
      <br />
      <b>9. Изменения условий соглашения</b>
      <br />
      Мы вправе в одностороннем порядке изменять условия данного пользовательского соглашения без уведомления пользователя. Актуальная версия пользовательского соглашения расположена по адресу{' '}
      <a href="https://donate-gold.ru/rules" class="link">
        https://donate-gold.ru/rules
      </a>
    </>
  ),
  ourIdOnSite: 'Ваш ID на сайте',
  vk: 'Вконтакте',
  onSite: 'На сайте',
  exitFromAccount: 'Выйти из аккаунта',

  guaranteText: (
    <>
      {' '}
      <b style={{ fontWeight: '600 !important' }}>Безопасность данных</b>
      <br />
      В нашей базе данных не хранятся ваши персональные данные и мы их у вас не запрашиваем. У нас хранится только ваши email, которые требуются при регистрации аккаунта. Мы не рассылаем вам спам и рекламные сторонние предложения. Наш сервис работает по защищенному протоколу https и использует
      сертификат безопасности SSL. Все транзакции на сайте происходят через официальные банковские шлюзы, где переданные данные надежно защищены и зашифрованы.
      <br />
      <br />
      Гарантии пополнения игровой валюты
      <br />
      Игровая валюта через наш сервис приходит на ваши игровые аккаунты моментально. Исключения могут составлять некоторые игры, и об этом указано в карточке игры. Все пополнения абсолютно легальны, банов ваших игровых аккаунтов не будет. Мы работаем или напрямую через API разработчика, или работаем
      с официальными интеграторами, такими как OffGamers, Codashop, Razer, SEA Gamer и другими. Все пополнения идут только по вашему игровому ID. Данных логина и пароля от вашего игрового аккаунта мы не запрашиваем и не храним.
    </>
  ),

  faqTitle: 'Вопросы и ответы',
  faqPageText: (
    <>
      Как работает сервис?
      <br />
      Наш сайт работает полностью в автоматическом режиме. Вы можете покупать игровую валюту и самостоятельно донатить себе в вашу игру, указывая только игровой ID.
      <br />
      <br />
      Это безопасно?
      <br />
      Да, это абсолютно безопасно, так как все пополнения идут через API разработчика или через официальных интеграторов, таких как OffGamers, Codashop, Razer, SEA Gamer и других. Банов ваших аккаунтов не будет.
      <br />
      <br />
      Придет ли мне бонусная игровая валюта?
      <br />
      Да, бонусная игровая валюта приходит точно так же, как если бы вы покупали паки через саму игру. Ниже пример покупки печатей в игре Identity V пака 60 печатей. Как видно, игроку приходит 63 печатей где 3 печати являются бонусными.
      <br />
      <br />
      <img src="/idv_bonus.jpg" style={{ height: 'auto', width: '100%' }} />
      <br />
      <br />
      <img src="/idv_bonus2.jpg" style={{ height: 'auto', width: '100%' }} />
      <br />
      <br />
      Предоставляете ли вы чеки как App Store и Google Play?
      <br />
      Чеки пополнения баланса вашего личного кабинета безусловно есть. А вместо чеков доната мы предоставляем уникальный номер вашей транзакции в игру. Этот номер записывается в базу данных разработчика и свидетельствует о том, что донат произвели именно вы. Также в личном кабинете у вас есть дата
      доната и количество игровой валюты, которую вы получили.
      <br />
      <br />
    </>
  ),
  technicalWorkText: 'На сайте ведутся технические работы. Скоро сайт заработает',
  aboutPageText: (
    <>
      {' '}
      Наш сервис позволяет донатить в мобильные и компьютерные игры напрямую разработчикам через их API. Все ваши транзакции абсолютно легальны. Банов ваших аккаунтов не будет, проверено тысячами пользователей.
      <br />
      <br />
      Позвольте коротко объяснить как это работает. В каждой современной игре есть внутриигровая валюта, будь то кристалы, монетки, печати, золото и прочие названия. Это донатная валюта, которая покупается за реальные деньги игроками. Игрок по сути обменивает валюту настоящую на валюту виртуальную,
      и покупают на неё различный контент в игре. Чтобы купить донатную валюту в играх существуют магазины, где игрок выбирая соответствующий номинал (пак валюты) - совершает покупку валюты (донатит). Сама покупка происходит через магазин приложений, например App Store или Google Play. Эти платформы
      удерживают с разработчика большой процент от перевода игрока. И чтобы снижать свои расходы, у каждого разработчика игры есть так называемые прямые продажи игровой валюты (Direct Payment или Direct Top Up). <br />
      <br />
      <img src="/shema.jpg" style={{ maxWidth: '100%' }} />
      <br />
      <br />
      В этом случае продажа игровой валюты идет не через платформы гигантов App Store и Google Play, а или напрямую через разработчика и свой веб-ресурс или через платежные агрегаторы. При этом техническая механика пополнения счета виртуальной игровой валютой в игре остается прежней. Все транзакции
      происходят через API разработчика. С единственной разницей, что вы указываете свой игровой ID аккаунта, куда хотите задонатить. Наш сервис этим и занимается.
      <br />
      <br />
    </>
  ),
  mainPageText:
    'На сайте Donate Gold вы можете задонатить в мобильные игры Genshin Impact, PUBG Mobile, Mobile legends, Identity V и другие. У нас вы можете отправить игровую валюту самостоятельно без посредников напрямую в игру себе или другу, без доступа к вашему аккаунту. Легко, Быстро и Безопасно. Donate Gold: донат в игру в 2025 году - ваше лучшее решение!',
  settings: 'Настройки',
  payments: 'Оплаты',
  donates: 'Донаты',
  donateLastThreeDays: ' Донатов за последние три дня не было',
  price: 'Цена',
  sum: 'Сумма',
  replenishmentOfPersonal: 'Пополнений личного счета за последние три дня не было',
  showMore: 'Показать ещё',
  promocodeText: ` Различные способы выиграть подарки. Список активностей будет обновляться!`,
  forSecurityText: <> В целях безопасности информация о ваших пополнениях личного счета отображается за последние три дня.</>,
  packageNotAvailable: (
    <>
      Данный пакет временно недоступен, напишите нам во{' '}
      <a style={{ color: '#e08418' }} href="https://vk.com/im?sel=-213480825">
        вконтакте
      </a>{' '}
      или в{' '}
      <a style={{ color: '#e08418' }} href="https://t.me/DonateGold">
        телеграм
      </a>{' '}
      и мы отправим вам печати, списав деньги с вашего личного счета сайта. Приносим извинения за временное неудобство.
    </>
  ),
  emailAlreadyExist: 'Такой email уже существует',
  linkSendingToEmail: (
    <>
      Ссылка на подтверждение <br /> отправлена на почту
    </>
  ),
  unexpectedError: (
    <>
      {' '}
      Упс, ошибка. <br /> Проверьте информацию в{' '}
      <a href="https://donate-gold.ru/profile" class="link">
        личном кабинете
      </a>
      , вкладка "донаты".{' '}
    </>
  ),
  clearCart: 'Очистить корзину',
  emptyCart: 'Корзина пуста',
  afterPaymentText: ' После оплаты деньги поступят на ваш лицевой счет сайта. Далее не забудьте задонатить игровую валюту в игру, кнопка "Задонатить" под вводом ID. Деньги спишутся с вашего лицевого счета.',
  editorAvatar: 'Редактор аватара',
  back: 'Назад',
  inCart: 'В корзину',
  addPackToCart: 'Добавить пак в корзину?',
  promocodeCopy: 'реферальный код скопирован',
  activeDuring: 'активен в течение',
  promoNotActive: 'реферальный код неактивен',
  generateNew: 'Сгенерировать новый',
  accruedAllTime: 'Начислено всего по программе',
  accruedToday: ' Начислено сегодня',
  refillShort: 'Пополнение счета',
  refill: 'Пополнение лицевого счета',
  currentMethodText: 'Данный метод пополнения счета больше резервный. В нем иногда не проходит оплата. Лучше воспользуйтесь пополнением счета через страницу игры — кнопочка "купить" в блоке пака, собрав корзину заказа.',
  moneyBoxText: 'В копилку вам приходит 0,5% от всех донатов, по достижению определенной суммы вы их сможете забрать.',
  numPack: 'Количество паков',
  refProgramm: 'Реферальная программа',
  refProgrammText: ` Ниже ваш код реферальной программы. Если пользователь зарегистрировался под вашим кодом, то вы будете получать 1% с его доната на ваш личный счет на время действия кода. Код действителен 30 дней, потом нужно создавать новый (появится соответствующая кнопка). Для администраторов
                  сообществ действуют специальные предложения. Если у вас есть сообщество, свяжитесь с нами для экслюзивных условий.`,
  ourPromo: 'Ваш реферальный код',
  promocode: 'Активности',
  idCopy: 'ID скопирован',
  idNotFound: 'ID не найден',
  newPasswordSendToMail: 'Новый пароль отправлен на почту',

  games: 'Игры',
  incorrectPromoCode: 'Некорректный реферальный код',
  emailExist: 'Такой email уже существует',
  reCaptchaFailed: 'reCaptcha не пройдена',
  promoCodeNotReq: 'Реферальный код (необязательно)',
  repeatPassword: 'Повторите пароль',
  passwordNotEqual: 'Пароли не совпадают',
  signIn: 'Вход',
  signInGoogle: 'Войти через Google',
  or: 'или',
  register: 'Регистрация',
  password: 'Пароль',
  moneyOver: 'Недостаточно средств',
  promoCodesOver: 'Реферальные коды закончились',
  min: 'мин',
  max: 'макс',
  willBeCredited: 'Будет зачисленно',
  selectServer: 'Выберите сервер',
  idTransaction: 'ID транзакции',
  loading: 'Загрузка...',
  refundedSuccess: 'Выполнен возврат',
  enter: 'Войти',
  sending: 'Отправляется',
  completed: 'Выполнен',
  inProgress: 'Выполняется',
  inputServer: 'Укажите Zone ID',
  ourGameId: 'Ваш игровой ID',
  inputAgain: 'Ввести снова',
  ourNick: 'Ваш ник',
  donateOnlyAndroid: 'Донат доступен только для аккаунтов, созданных на Android',
  acceptDonate: 'Подтверждаем донат?',
  yourDonateSending: (
    <>
      {' '}
      Ваш донат отправляется, дождитесь окончания <br /> и не закрывайте страницу
    </>
  ),
  topUpAccountText: 'Через данную кнопку вы можете пополнить свой личный счет на сайте на любую сумму.',
  topUpAccount: ' Пополнить счет',
  nowDay: 'сегодняшнего дня',
  days: 'дней',
  topUp: 'Пополнить',
  topUpReserve: 'Пополнить счет (резерв)',
  topUpBalance: 'Пополнить баланс',
  topUpYourAccount: 'Пополнить счет на',
  save: 'Сохранить',
  take: 'Забрать',
  yes: 'Да',
  no: 'Нет',
  moneyBox: 'Копилка',
  saving: 'Накопления',
  close: 'Закрыть',
  supportUs: 'Поддержать нас',
  change: 'Сменить',
  name: 'Ник на сайте',
  donate: 'Выбрать пак',
  pageNotFound: 'Страница не найдена',
  termsOfUse: 'Пользовательское соглашение',
  privacyPolicy: 'Политика конфиденциальности',
  privacyPolicyText: (
    <>
      Конфидециальность ваших данных является одним из приоритетов работы нашего сервиса. В данном документе содержится список информация, которую мы собираем и то как мы её используем.
      <br />
      <br />
      Куки (cookies)
      <br />
      Мы используем файлы cookies на нашем сайте. Используя сайт Donate Gold, вы соглашаетесь использовать файлы cookies в соответствии с нашей политикой конфиденциальности.
      <br />
      <br />
      Логирование
      <br />
      Мы используем для записи действий на сайте стандартные log файлы. Когда пользователь находится на сайте, стандартными способами регистрации действий на сайте фиксируются в log файлы. Записываются IP пользователей, тип браузера, дата и время посещения страницы, переходы на другие страницы и
      клики. Эти данные никак не индетифицируют пользователя. Данная информация предназначена только для общего анализа поведения пользователя на сайте.
      <br />
      <br />
      Какие данные мы собираем и храним
      <br />
      - Email пользователя - используется для регистрации пользователя и необходим для авторизации.
      <br />
      Мы не проверяем достоверность этих данных, предоставляемой пользователями, и не осуществляет контроль за их дееспособностью. Однако мы исходим из того, что пользователь предоставляет достоверную информацию, указанные им при регистрации данные принадлежат лично ему и поддерживает эту информацию
      в актуальном состоянии. Риск предоставления недостоверной информации несет предоставивший ее пользователь.
      <br />
      <br />
      - Баланс пользователя на сайте, суммы, даты, количество и наименование покупок.
      <br />
      Указанные данные собираются и хранятся для статистической информации, а так же для предоставления этих данных конкретному пользователю его данных.
      <br />
      <br />
      Все указанные выше данные хранятся в строгой конфиденциальности, кроме случаев добровольного предоставления пользователем информации о себе для общего доступа неограниченному кругу лиц.
      <br />
      <br />
      Сервис вправе передать указанные данные пользователя третьим лицам, если передача предусмотрена российским или иным применимым законодательством в рамках установленной законодательством процедуры.
      <br />
      <br />
      Пользователь вправе полностью удалить свой аккаунт со всеми его пользовательскими данными.
      <br />
      <br />
      Мы вправе вносить изменения в настоящую политику конфиденциальности. Новая редакция политики конфиденциальности вступает в силу с момента ее опубликования на данной странице.
      <br />
      <br />
    </>
  ),
  returnPolicy: 'Политика возврата',
  refundPolicy: 'Политика возврата средств',
  refundPolicyText: (
    <>
      {' '}
      Возврат внутригровой валюты
      <br />
      Любое пополнение игровой валюты является окончательным.
      <br />
      Если вы отправили игровую валюту в игру, возврат средств не производится.
      <br /> Если вы ввели неправильный игровой ID, то после пополнения игровой валюты возврат невозможен.
      <br />
      Мы не несем ответственность за утрату или кражу вашего игрового аккаунта.
      <br />
      <br />
      Право на возврат средств с баланса личного кабинета
      <br />
      Средства с вашего личного баланса нашего сервиса могут быть возвращены в полном объеме. Если ваш лицевой счет был пополнен с кошелька WebMoney, полный возврат средств будет исполнен на тот же кошелек, с которого вы перевели средства.
      <br />
      Для возврата свяжитесь с нами по электронной почте support@donate-gold.ru
      <br />
      <br />
    </>
  ),
  idSuccess: 'ID проверен',
  incorrectLoginOrPass: 'Неправильный логин или пароль',
  check: 'Проверить',
  acceptEmail: 'Подтвердите email',
  rememberMail: 'Запомнить почту',
  forgotPassword: 'Забыли пароль?',
  zoom: 'Зум',
  email: 'Email',
  technicalSupport: 'Техническая поддержка',
  technicalSupportText: (
    <>
      <span>По вопросам работы сайта обращайтесь:</span>
      <br />
      ◦ В чат поддержки (внизу справа)
      <br />◦ Телеграм{' '}
      <a class="link" href="https://t.me/DonateGold" target="_blank">
        @DonateGold
      </a>{' '}
      <br />
      ◦ email - support@donate-gold.ru
      <br />
      <br />
      Режим работы: ежедневно с 10:00 до 24:00
      <br />
      Реклама, сотрудничество -{' '}
      <a class="link" href="https://t.me/Aleksandra_Tumanova" target="_blank">
        Александра
      </a>
      <br />
      <br />
      <br />
      Наш маскот - японский котик Манэки-нэко, который желает счастье тем, кто пользуются сервисом. Автор логотипа и задумки - наш пользователь сервиса Ян Вэй.
      <br />
      <br />
    </>
  ),
  accept: 'Подтвердить',
  game1Name: 'Донат в игру Identity V',
  game1BottomDesc: (
    <>
      Identity V — асимметричная многопользовательская игра в жанре survival horror, разработанная китайской компанией NetEase Games. Игра доступна на мобильных телефонах и ПК.
      <br />
      <br />
      В игре присутствуют две фракции - Выжившие и Охотники. На различных картах Выжившие стараются убежать от Охотника, используя индивидуальные способности, и выбраться с территории. Охотник в свою очередь должен поймать их, пока они не сбежали. Игра выдержана в готическом стиле начала 20 века,
      разработчики вдохновлялись стилистикой Тима Бёртона и игровой механикой Dead by Daylight.
      <br />
      <br />
      <a class="link" href="https://identityvgame.com">
        Официальный сайт игры
      </a>
      <br />
      <br />
      <img src="/idv-about.jpg" style={{ maxWidth: '100%' }} />
    </>
  ),
  game1Desc: <>Донат в Identity V по ID. Печати также засчитываются в игровой счет Мисс Соловья. Теперь легко и безопасно можно купить себе желанный костюм! </>,
  game1Privacy: (
    <>
      Игровую валюту на данной странице вы донатите в пользу NetEase Games. Мы не являемся правообладателем игровой валюты Identity V, не определяем порядок её использования и функционирования в игре. Производя донат в игру Identity V вы соглашаетесь с данным{' '}
      <a class="link" href="https://game.longeplay.com.tw/member/complete_agreement">
        пользовательским соглашением
      </a>
      ,{' '}
      <a class="link" href=" https://donate-gold.ru/terms-of-use">
        пользовательским соглашением Donate Gold
      </a>{' '}
      и нашей{' '}
      <a class="link" href="https://donate-gold.ru/privacy-policy">
        политикой конфиденциальности
      </a>
      .
    </>
  ),
  game1Meta: (
    <Helmet>
      <title>Донат в Identity V</title>
      <meta name="description" content="Как донатить в Identity V? Нужно указать ID и сервер. Донат приходит моментально. Донат в Identity V в 2025 году." />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),
  game2Name: 'Донат в игру Genshin Impact',
  game2BottomDesc: (
    <>
      Genshin Impact — компьютерная игра в жанре action-adventure с открытым миром и элементами RPG, разработанная китайской компанией miHoYo Limited.
      <br />
      <br />
      По сюжету герой по имени Путешественник странствует в фэнтезийном мире Тейват в поисках своей сестры-близнеца (или брата). Попутно участвуя в делах этого мира. Его сопровождает спутница-компаньон Паймон, и другие игровые персонажи. Каждый персонаж имеет уникальные способности, основанные на
      одной из семи стихий.
      <br />
      <br />
      <a class="link" href="https://genshin.hoyoverse.com">
        Официальный сайт игры
      </a>
      <br />
      <br />
      <img src="/genshin-about.jpg" style={{ maxWidth: '100%' }} />
    </>
  ),
  game2Desc: <>Донат в Genshin Impact по UID. Обращаем внимание, кристаллы приходят в игру в течение нескольких минут, время поступления зависит от загруженности сервера.</>,
  game2Privacy: (
    <>
      Игровую валюту на данной странице вы донатите в пользу HoYoverse (miHoYo). Мы не являемся правообладателем игровой валюты Genshin Impact, не определяем порядок её использования и функционирования в игре. Производя донат в игру Genshin Impact вы соглашаетесь с данным{' '}
      <a class="link" href="https://genshin.hoyoverse.com/ru/company/privacy">
        {' '}
        пользовательским соглашением
      </a>
      ,{' '}
      <a class="link" href=" https://donate-gold.ru/terms-of-use">
        пользовательским соглашением Donate Gold
      </a>{' '}
      и нашей{' '}
      <a class="link" href="https://donate-gold.ru/privacy-policy">
        политикой конфиденциальности
      </a>
      .
    </>
  ),
  game2Meta: (
    <Helmet>
      <title>Донат в Genshin Impact</title>
      <meta name="description" content="Как донатить в Genshin Impact? Нужно указать UID и сервер. Донат приходит моментально. Донат в Genshin Image в 2025 году. Купить примогемы." />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),
  game3Name: 'Донат в игру Onmyoji Arena',
  game3BottomDesc: (
    <>
      Onmyoji Arena — мобильная игра в жанре MOBA, разработанная китайской компанией NetEasy Games. Является спин-оффом серии Onmyoji.
      <br />
      <br />
      Игра основана на японском фильме «Колдун», повествующем о событиях времен периода Хэйан. В игре присутствуют множетсво персонажей Shikigami со своими уникальными особенностями и классами. Среди режимов игры есть и классические матчи 5v5, так и 3v3v3 Battle Royale. Игра постоянно обновляется.
      <br />
      <br />
      <a class="link" href="https://www.onmyojiarena.us">
        Официальный сайт игры
      </a>
      <br />
      <br />
      <img src="/onmyoji-about.jpg" style={{ maxWidth: '100%' }} />
    </>
  ),
  game3Desc: (
    <>
      Донат в игру Onmyoji Arena по ID. Отправить Jade можно только на глобальный сервер. Чтобы прошел донат для iOS, сначала зайдите в игре в свой аккаунт, сверните приложение, и откройте сайт. Затем, сделайте проверку ID и если ваш аккаунт нашелся - делайте донат. Для Android устройств заходить в
      игру не нужно.
    </>
  ),
  game3Privacy: (
    <>
      Игровую валюту на данной странице вы донатите в пользу NetEase Games. Мы не являемся правообладателем игровой валюты Onmyoji Arena, не определяем порядок её использования и функционирования в игре. Производя донат в игру Onmyoji Arena вы соглашаетесь с данным{' '}
      <a class="link" href="https://www.onmyojiarena.us/law.html">
        пользовательским соглашением
      </a>
      ,{' '}
      <a class="link" href=" https://donate-gold.ru/terms-of-use">
        пользовательским соглашением Donate Gold
      </a>{' '}
      и нашей{' '}
      <a class="link" href="https://donate-gold.ru/privacy-policy">
        политикой конфиденциальности
      </a>
      .
    </>
  ),
  game3Meta: (
    <Helmet>
      <title>Донат в Onmyoji Arena</title>
      <meta name="description" content="Как донатить в Onmyoji Arena? Нужно указать ID и сервер. Донат приходит моментально. Донат в Onmyoji Arena в 2025 году." />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),

  game4Name: 'Донат в игру The Lord of the Rings: Rise to War',
  game4BottomDesc: (
    <>
      The Lord of the Rings: Rise to War — мобильная стратегия, основанная на культовой трилогии Дж. Р. Р. Толкиена разработанная компанией NetEase Games. В русском переводе звучит как Властелин колец: Битва воинств.
      <br />
      <br />
      В течение сезонных игр вам предстоит собрать командиров и экспедиционные отряды, расширять свои владения, собирать ценные ресурсы и разбивать многичесленные армии врагов. Опыт и сила помогут вам одолеть любые неожиданности и опасности на вашем пути.
      <br />
      <br />
      <a class="link" href="https://www.lotr-risetowar.com">
        Официальный сайт игры
      </a>
      <br />
      <br />
      <img src="/onmyoji-arena-bg.jpg" style={{ maxWidth: '100%' }} />
    </>
  ),
  game4Desc: <>Донат в игру The Lord of the Rings: Rise to War. Донат во Властелин Колец: Битва Воинств по ID. Сезонный Пропуск покупается на срок активности сезона, а Очередь Строительства на 30 дней. Если вы купите данные предметы второй раз до истечения их срока, они превратятся в самоцветы.</>,
  game4Privacy: (
    <>
      Игровую валюту на данной странице вы донатите в пользу NetEase Games. Мы не являемся правообладателем игровой валюты The Lord of the Rings: Rise to War, не определяем порядок её использования и функционирования в игре. Производя донат в игру The Lord of the Rings: Rise to War вы соглашаетесь с
      данным{' '}
      <a class="link" href="https://unisdk.update.netease.com/html/latest_v30.html">
        пользовательским соглашением
      </a>
      ,{' '}
      <a class="link" href=" https://donate-gold.ru/terms-of-use">
        пользовательским соглашением Donate Gold
      </a>{' '}
      и нашей{' '}
      <a class="link" href="https://donate-gold.ru/privacy-policy">
        политикой конфиденциальности
      </a>
      .
    </>
  ),
  game4Meta: (
    <Helmet>
      <title>Донат в игру The Lord of the Rings: Rise to War</title>
      <meta name="description" content="Как донатить в The Lord of the Rings? Нужно указать ID и сервер. Донат приходит моментально. Донат в The Lord of the Rings в 2025 году. Донат в Властелин Колец Битву Воинств." />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),

  game5Name: 'Донат в игру Mobile legends',
  game5BottomDesc: (
    <>
      Mobile Legends: Bang Bang — мобильная многопользовательская онлайн игра в жанре MOBA, разработанная компанией Moonton.
      <br />
      <br />
      На арене сражаются две команды 5 vs 5 уничтожая замок противника, одновременно защищая свой. Каждый игрок управляем своим персонажем героем, у которого есть различные уникальные способности. Каждая партия игры неповторима, и включает как тактические, так и стратегические приемы. В игре более
      100 персонажей. Кроме классического режима есть аркадные игры и режим 3 vs 3.
      <br />
      <br />
      <a class="link" href="https://mobilelegends.com">
        Официальный сайт игры
      </a>
      <br />
      <br />
      <img src="/ml-about.jpg" style={{ maxWidth: '100%' }} />
    </>
  ),
  game5Desc: <>Донат в Mobile Legends: Bang Bang по ID. Алмазы в мобайл ледженс приходит почти сразу. В поле zone id укажите 4-5 цифр, которые написаны у вас в скобочках после ID.</>,
  game5Privacy: (
    <>
      Игровую валюту на данной странице вы донатите в пользу Moonton. Мы не являемся правообладателем игровой валюты Mobile legends: Bang Bang, не определяем порядок её использования и функционирования в игре. Производя донат в игру Mobile legends: Bang Bang вы соглашаетесь с данным{' '}
      <a class="link" href="https://m.mobilelegends.com/en/newsdetail/475">
        пользовательским соглашением
      </a>
      ,{' '}
      <a class="link" href=" https://donate-gold.ru/terms-of-use">
        пользовательским соглашением Donate Gold
      </a>{' '}
      и нашей{' '}
      <a class="link" href="https://donate-gold.ru/privacy-policy">
        политикой конфиденциальности
      </a>
      .
    </>
  ),
  game5Meta: (
    <Helmet>
      <title>Донат в игру Mobile legends</title>
      <meta name="description" content="Как донатить в Mobile legends: Bang Bang? Нужно указать ID и сервер. Донат приходит моментально. Донат в Mobile legends в 2025 году." />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),

  game6Name: 'Донат в игру PUBG Mobile (Global)',
  game6BottomDesc: (
    <>
      PUBG Mobile — мобильная многопользовательская онлайн-игра в жанре королевской битвы, разработанная LightSpeed & Quantum Studio. Игра является мобильной версией оригинальной PlayerUnknown’s Battlegrounds, разработанной PUBG Corporation.
      <br />
      <br />
      Игроков сбрасывают с парашютом на остров, на котором до ста участников сражаются друг с другом. Должен остаться единственный выживший или единственная команда.
      <br />
      <br />
      <a class="link" href="https://www.pubgmobile.com">
        Официальный сайт игры
      </a>
      <br />
      <br />
      <img src="/pubg-about.jpg" style={{ maxWidth: '100%' }} />
    </>
  ),
  game6Desc: <>Донат в PUBG Mobile по ID. Будьте внимательны при заполнении данных. UC в мобильный ПАБГ приходит почти моментально!</>,
  game6Privacy: (
    <>
      Игровую валюту на данной странице вы донатите в пользу LightSpeed & Quantum Studio. Мы не являемся правообладателем игровой валюты LightSpeed & Quantum Studio, не определяем порядок её использования и функционирования в игре. Производя донат в игру PUBG Mobile (Global) вы соглашаетесь с данным{' '}
      <a class="link" href="http://www.pubgmobile.com/terms.html">
        пользовательским соглашением
      </a>
      ,{' '}
      <a class="link" href=" https://donate-gold.ru/terms-of-use">
        пользовательским соглашением Donate Gold
      </a>{' '}
      и нашей{' '}
      <a class="link" href="https://donate-gold.ru/privacy-policy">
        политикой конфиденциальности
      </a>
      .
    </>
  ),
  game6Meta: (
    <Helmet>
      <title>Донат в игру PUBG Mobile (Global)</title>
      <meta name="description" content="Как донатить в PUBG Mobile (Global)? Достаточно указать ID. Донат приходит моментально. Донат в PUBG Mobile (Global) в 2025 году. Донат в пубг мобильный." />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),
  game7Name: 'Донат в игру Clash of Clans',
  game7BottomDesc: (
    <>
      Clash of Clans — мобильная многопользовательская стратегическая игра, разработанная Supercell. В игре предстоит тщательно планировать и рассчитывать каждое действие, оборонять свою деревню от других игроков, и атаковать их самому. Одна из главных возможностей игры — создание клана и участие в
      «Клановых войнах».
      <br />
      <br />
      <a class="link" href="https://www.supercell.com">
        Официальный сайт игры
      </a>
      <br />
      <br />
      <img src="/clash-of-clans-about.jpg" style={{ maxWidth: '100%' }} />
    </>
  ),
  game7Desc: <>Донат в игру Clash of Clans самостоятельно, указывая только ваш игровой тэг (Player Tag). Теперь в любимую игру Клэш оф Клэнс вы можете задонатить быстро и безопасно.</>,
  game7Privacy: (
    <>
      Игровую валюту на данной странице вы донатите в пользу Supercell. Мы не являемся правообладателем игровой валюты Supercell, не определяем порядок её использования и функционирования в игре. Производя донат в игру Clash of Clans вы соглашаетесь с данным{' '}
      <a class="link" href="https://supercell.com/en/terms-of-service/">
        пользовательским соглашением
      </a>
      ,{' '}
      <a class="link" href=" https://donate-gold.ru/terms-of-use">
        пользовательским соглашением Donate Gold
      </a>{' '}
      и нашей{' '}
      <a class="link" href="https://donate-gold.ru/privacy-policy">
        политикой конфиденциальности
      </a>
      .
    </>
  ),
  game7Meta: (
    <Helmet>
      <title>Донат в игру Clash of Clans</title>
      <meta name="description" content="Как донатить в Clash of Clans? Достаточно указать тег ID. Донат приходит моментально. Донат в Clash of Clans в 2025 году. Донат в Клэш оф Клэнс." />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),
  game8Name: 'Донат в игру Clash Royale',
  game8BottomDesc: (
    <>
      Clash Royale — мобильная многопользовательская онлайн-игра в жанре в жанре RTS с элементами ККИ, разработанная Supercell. В игре присутствуют любимые персонажи из{' '}
      <a class="link" href="/game/clash-of-clans">
        Clash of Clans
      </a>
      .
      <br />
      <br />
      Геймплей строится на битвах, в которых вам нужно уничтожать вражеские башни с помощью уникальных карт, а также оборонять свои башни от врага.
      <br />
      <br />
      <a class="link" href="https://www.pubgmobile.com">
        Официальный сайт игры
      </a>
      <br />
      <br />
      <img src="/clash-royale-about.jpg" style={{ maxWidth: '100%' }} />
    </>
  ),
  game8Desc: <>Донат в игру Clash Royale самостоятельно, указывая только ваш игровой тэг (Player Tag). Теперь в любимую игру Клеш Рояль вы можете задонатить быстро и безопасно.</>,
  game8Privacy: (
    <>
      Игровую валюту на данной странице вы донатите в пользу Supercell. Мы не являемся правообладателем игровой валюты Supercell, не определяем порядок её использования и функционирования в игре. Производя донат в игру Clash Royale вы соглашаетесь с данным{' '}
      <a class="link" href="https://supercell.com/en/terms-of-service/">
        пользовательским соглашением
      </a>
      ,{' '}
      <a class="link" href=" https://donate-gold.ru/terms-of-use">
        пользовательским соглашением Donate Gold
      </a>{' '}
      и нашей{' '}
      <a class="link" href="https://donate-gold.ru/privacy-policy">
        политикой конфиденциальности
      </a>
      .
    </>
  ),
  game8Meta: (
    <Helmet>
      <title>Донат в игру Clash Royale</title>
      <meta name="description" content="Как донатить в Clash Royale? Достаточно указать ID. Донат приходит моментально. Донат в Clash Royale в 2025 году. Донат в Клеш Рояль мобильный." />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),
  game9Name: 'Донат в игру Brawl Stars',
  game9BottomDesc: (
    <>
      Brawl Stars — мобильная многопользовательская онлайн-игра в жанре MOBA. Есть несколько режимов игры, такие как Showdown, Gem Grab, Bounty, Heist, Brawl Ball и другие. Игра разработана компанией Supercell.
      <br />
      <br />
      Объединяйтесь с друзьями или играйте в соло в быстрые матчи с яркими битвами!
      <br />
      <br />
      <a class="link" href="https://www.supercell.com">
        Официальный сайт игры
      </a>
      <br />
      <br />
      <img src="/brawl-stars-gameplay.jpg" style={{ maxWidth: '100%' }} />
    </>
  ),
  game9Desc: <>Донат в игру Brawl Stars самостоятельно, указывая только ваш игровой тэг (Player Tag). Теперь в любимую игру Бравл Старс вы можете задонатить быстро и безопасно.</>,
  game9Privacy: (
    <>
      Игровую валюту на данной странице вы донатите в пользу Supercell. Мы не являемся правообладателем игровой валюты Supercell, не определяем порядок её использования и функционирования в игре. Производя донат в игру Brawl Stars вы соглашаетесь с данным{' '}
      <a class="link" href="https://supercell.com/en/terms-of-service/">
        пользовательским соглашением
      </a>
      ,{' '}
      <a class="link" href=" https://donate-gold.ru/terms-of-use">
        пользовательским соглашением Donate Gold
      </a>{' '}
      и нашей{' '}
      <a class="link" href="https://donate-gold.ru/privacy-policy">
        политикой конфиденциальности
      </a>
      .
    </>
  ),
  game9Meta: (
    <Helmet>
      <title>Донат в игру Brawl Stars</title>
      <meta name="description" content="Как донатить в Brawl Stars? Достаточно указать тег ID. Донат приходит моментально. Донат в Brawl Stars в 2025 году. Донат в бравл старс." />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),
  game10Name: 'Пополнение кошелька Steam',
  game10BottomDesc: (
    <>
      Описание
      <img src="/pubg-about.jpg" style={{ maxWidth: '100%' }} />
    </>
  ),
  game10Desc: (
    <>
      <ul className="game__list">
        <li className="game__list-item"> На данной странице вы донатите в игру Brawl Stars самостоятельно, указывая только ваш игровой тэг (Player Tag)</li>
        <li className="game__list-item"> Донаты абсолютно легальны, наш сайт автоматизирует пополнение через официальных поставщиков</li>
        <li className="game__list-item"> С каждым паком вам приходит бонусная игровая валюта</li>
        <li className="game__list-item"> Донаты приходят в течение 5-25 минут</li>
        <li className="game__list-item"> Если возникла ошибка при донате, повторите попытку или напишите нам</li>
        <li className="game__list-item"> Мы не несем ответственности, если вы указали неверный тэг</li>
        <li className="game__list-item"> Деньги сначала зачисляются на ваш лицевой счет сайта, потом вы их донатите в игру</li>
      </ul>
    </>
  ),
  game10Privacy: (
    <>
      Игровую валюту на данной странице вы донатите в пользу Supercell. Мы не являемся правообладателем игровой валюты Supercell, не определяем порядок её использования и функционирования в игре. Производя донат в игру Brawl Stars вы соглашаетесь с данным{' '}
      <a class="link" href="https://supercell.com/en/terms-of-service/">
        пользовательским соглашением
      </a>
      ,{' '}
      <a class="link" href=" https://donate-gold.ru/terms-of-use">
        пользовательским соглашением Donate Gold
      </a>{' '}
      и нашей{' '}
      <a class="link" href="https://donate-gold.ru/privacy-policy">
        политикой конфиденциальности
      </a>
      .
    </>
  ),
  game10Meta: (
    <Helmet>
      <title>Донат </title>
      <meta name="description" content="Как донатить в PUBG Mobile (Global)? Достаточно указать ID. Донат приходит моментально. Донат в PUBG Mobile (Global) в 2025 году. Донат в пубг мобильный." />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),
  game11Name: 'Донат в Honkai: Star Rail',
  game11BottomDesc: (
    <>
      Компьютерная и мобильная игра в жанре action-adventure с полностью закрытым миром и элементами RPG. Разработанная и изданная китайской компанией HoYoverse. Это вторая часть серии Honkai, в которой используются новые персонажи наряду с альтернативными версиями существующих персонажей из Honkai
      Impact 3rd.
    </>
  ),
  game11Desc: <>У нас вы можете купить Звездный нефрит (Stellar Jade) и задонатить в Honkai: Star Rail. После покупки звёздного нефрита напишите нам в чат.</>,
  game11Privacy: <>У нас вы можете сделать донат в Honkai: Star Rail. Донат полностью автоматический и требуется только ваш UID.</>,
  game11Meta: (
    <Helmet>
      <title>Донат в игру Honkai: Star Rail</title>
      <meta name="description" content="Как донатить в Honkai: Star Rail? Купить звездный нефрит по выгодным ценам. Донат в Хонкай Стар Рэйл." />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),
  game11Name: 'Донат в Honkai: Star Rail',
  game11BottomDesc: (
    <>
      Компьютерная и мобильная игра в жанре action-adventure с полностью закрытым миром и элементами RPG. Разработанная и изданная китайской компанией HoYoverse. Это вторая часть серии Honkai, в которой используются новые персонажи наряду с альтернативными версиями существующих персонажей из Honkai
      Impact 3rd.
    </>
  ),
  game11Desc: <>У нас вы можете купить Звездный нефрит (Stellar Jade) и задонатить в Honkai: Star Rail.</>,
  game11Privacy: (
    <>
      Игровую валюту на данной странице вы донатите в пользу HoYoverse (miHoYo). Мы не являемся правообладателем игровой валюты Supercell, не определяем порядок её использования и функционирования в игре. Производя донат в игру Honkai: Star Rail вы соглашаетесь с данным{' '}
      <a class="link" href="https://hsr.hoyoverse.com/ru-ru/company/terms">
        пользовательским соглашением
      </a>
      ,{' '}
      <a class="link" href=" https://donate-gold.ru/terms-of-use">
        пользовательским соглашением Donate Gold
      </a>{' '}
      и нашей{' '}
      <a class="link" href="https://donate-gold.ru/privacy-policy">
        политикой конфиденциальности
      </a>
      .
    </>
  ),
  game11Meta: (
    <Helmet>
      <title>Донат в игру Honkai: Star Rail</title>
      <meta name="description" content="Как донатить в Honkai: Star Rail? Купить звездный нефрит по выгодным ценам. Донат в Хонкай Стар Рэйл." />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),
  game12Name: 'Донат в Harry Potter: Magic Awakened',
  game12BottomDesc: <>«Гарри Поттер: Пробуждение Магии» (Harry Potter: Magic Awakened) — это карточная RPG игра для мобильных устройств, разработанная Warner Bros и Netease Games. Проверьте свои навыки против других ведьм и волшебников в захватывающих многопользовательских дуэлях!</>,
  game12Desc: <>У нас вы можете задонатить Драгоценные камни (Jewels) или просто гемы в «Гарри Поттер: Пробуждение Магии» (Harry Potter: Magic Awakened). Обратите внимание, что мы поддеживаем клиент игры только от NetEase. Большинство в ру-сообществе играет на этом клиенте игры.</>,
  game12Privacy: (
    <>
      Игровую валюту на данной странице вы донатите в пользу NetEase. Мы не являемся правообладателем игровой валюты NetEase, не определяем порядок её использования и функционирования в игре. Производя донат в игру Harry Potter: Magic Awakened вы соглашаетесь с данным{' '}
      <a class="link" href="https://protocol.unisdk.easebar.com/release/latest_v486.html">
        пользовательским соглашением
      </a>
      ,{' '}
      <a class="link" href=" https://donate-gold.ru/terms-of-use">
        пользовательским соглашением Donate Gold
      </a>{' '}
      и нашей{' '}
      <a class="link" href="https://donate-gold.ru/privacy-policy">
        политикой конфиденциальности
      </a>
      .
    </>
  ),
  game12Meta: (
    <Helmet>
      <title>Донат в игру Harry Potter: Magic Awakened</title>
      <meta name="description" content="Как донатить в Harry Potter: Magic Awakened «Гарри Поттер: Пробуждение Магии»? Донат в «Гарри Поттер: Пробуждение Магии»." />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),
  game13Name: 'Донат в Life After',
  game13BottomDesc: <>Мобильная игра в жанре 3D экшен-выживания в зомби-апокалипсисе. Вам нужно искать снаряжение и припасы, строить защиту, взаимодействовать с другими игроками и сражаться с зомби. Разработана компанией NetEase Games.</>,
  game13Desc: <>У нас вы можете задонатить кредиты в игру Life After (Жизнь после или Лайф Афтер). Мы поддерживаем клиент от компании NetEase Games. </>,
  game13Privacy: (
    <>
      Игровую валюту на данной странице вы донатите в пользу NetEase. Мы не являемся правообладателем игровой валюты NetEase, не определяем порядок её использования и функционирования в игре. Производя донат в игру Life After вы соглашаетесь с данным{' '}
      <a class="link" href="https://protocol.unisdk.easebar.com/release/latest_v486.html">
        пользовательским соглашением
      </a>
      ,{' '}
      <a class="link" href=" https://donate-gold.ru/terms-of-use">
        пользовательским соглашением Donate Gold
      </a>{' '}
      и нашей{' '}
      <a class="link" href="https://donate-gold.ru/privacy-policy">
        политикой конфиденциальности
      </a>
      .
    </>
  ),
  game13Meta: (
    <Helmet>
      <title>Донат в игру Life After</title>
      <meta name="description" content="Как донатить в Life After? Купить кредиты в Life After выгодно. Донат в Life After" />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),
  game14Name: 'Донат в Knives Out',
  game14BottomDesc: <>Компьютерная и мобильная игра в жанре Playerunknown Battlegrounds. На локации между собой воюют до 100 игроков, используя различное оружие и транспорт. В живых остается только один. Игра разработана компанией NetEase Games.</>,
  game14Desc: <>У нас вы можете задонатить ваучеры в Knives Out, на некоторые паки приходит бонусная валюта.</>,
  game14Privacy: (
    <>
      Игровую валюту на данной странице вы донатите в пользу NetEase. Мы не являемся правообладателем игровой валюты NetEase, не определяем порядок её использования и функционирования в игре. Производя донат в игру Knives Out вы соглашаетесь с данным{' '}
      <a class="link" href="https://protocol.unisdk.easebar.com/release/latest_v486.html">
        пользовательским соглашением
      </a>
      ,{' '}
      <a class="link" href=" https://donate-gold.ru/terms-of-use">
        пользовательским соглашением Donate Gold
      </a>{' '}
      и нашей{' '}
      <a class="link" href="https://donate-gold.ru/privacy-policy">
        политикой конфиденциальности
      </a>
      .
    </>
  ),
  game14Meta: (
    <Helmet>
      <title>Донат в игру Knives Out</title>
      <meta name="description" content="Как донатить в Knives Out? Купить ваучеры по выгодным ценам. Донат в Knives Out." />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),
  game15Name: 'Донат в Ace racer',
  game15BottomDesc: (
    <>
      Ace racer - это гоночная мобильная игра, в которой представлены инновационные автомобили с непревзойденными навыками. Испытайте различные реальные автомобили от легендарных производителей. Уникальная система матчей 3v3 добавит еще больше острых ощущений в вашу скоростную игру! Игра разработана
      компанией NetEase Games.{' '}
    </>
  ),
  game15Desc: <>У нас вы можете купить токены Ace racer по выгодным ценам. Доставка осуществляется мгновенно.</>,
  game15Privacy: (
    <>
      Игровую валюту на данной странице вы донатите в пользу NetEase. Мы не являемся правообладателем игровой валюты NetEase, не определяем порядок её использования и функционирования в игре. Производя донат в игру Ace racer вы соглашаетесь с данным{' '}
      <a class="link" href="https://protocol.unisdk.easebar.com/release/latest_v486.html">
        пользовательским соглашением
      </a>
      ,{' '}
      <a class="link" href=" https://donate-gold.ru/terms-of-use">
        пользовательским соглашением Donate Gold
      </a>{' '}
      и нашей{' '}
      <a class="link" href="https://donate-gold.ru/privacy-policy">
        политикой конфиденциальности
      </a>
      .
    </>
  ),
  game15Meta: (
    <Helmet>
      <title>Донат в игру Ace racer</title>
      <meta name="description" content="Как донатить в Ace racer? Купить токены Ace racer. Донат в Ace racer." />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),
  game16Name: 'Донат в Infinite Lagrange',
  game16BottomDesc: (
    <>
      Infinite Lagrange – это космическая стратегия, с которой вы отправитесь в далекие межгалактические приключения. Управляйте своей космической командой, принимайте важные решения, например, выбор кораблей, укрепление станций и атака врагов – все зависит от территории, которую вы создаете. В игре
      представлено огромное множество кораблей и полная свобода действий. Разработчик: NetEase Games.
    </>
  ),
  game16Desc: <>У нас вы можете купить Чу-Коины (Chu-Coins) и задонатить их в Infinite Lagrange. Вместе в донатными монетами приходят Tech points (очки технологий), которые используются для улучшения возможностей кораблей. </>,
  game16Privacy: (
    <>
      Игровую валюту на данной странице вы донатите в пользу NetEase. Мы не являемся правообладателем игровой валюты NetEase, не определяем порядок её использования и функционирования в игре. Производя донат в игру Infinite Lagrange вы соглашаетесь с данным{' '}
      <a class="link" href="https://protocol.unisdk.easebar.com/release/latest_v486.html">
        пользовательским соглашением
      </a>
      ,{' '}
      <a class="link" href=" https://donate-gold.ru/terms-of-use">
        пользовательским соглашением Donate Gold
      </a>{' '}
      и нашей{' '}
      <a class="link" href="https://donate-gold.ru/privacy-policy">
        политикой конфиденциальности
      </a>
      .
    </>
  ),
  game16Meta: (
    <Helmet>
      <title>Донат в игру Infinite Lagrange</title>
      <meta name="description" content="Как донатить в Infinite Lagrange? Купить Чу-коины по выгодным ценам. Донат в Infinite Lagrange." />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),
  game17Name: 'Донат в Dead by Daylight Mobile',
  game17BottomDesc: (
    <>
      Dead by Daylight Mobile - это многопользовательская игра в жанре хоррор-выживание (survival horror), в которой один безжалостный убийца выслеживает четырех выживших. Игра созданна как мобильная адаптация культовой игры на PC Dead by Daylight™. Мобильная версия разработана компанией NetEase
      Games.
    </>
  ),
  game17Desc: <>У нас вы можете купить золотые клетки и задонатить их в Dead by Daylight Mobile. Обратите внимание, у нас поддерживается донат только в версию от NetEase. Вместе со стандартным набором золотых клеток приходят и бонусные. Пополнение игровой валюты мгновенное.</>,
  game17Privacy: (
    <>
      Игровую валюту на данной странице вы донатите в пользу NetEase. Мы не являемся правообладателем игровой валюты NetEase, не определяем порядок её использования и функционирования в игре. Производя донат в игру Dead by Daylight Mobile вы соглашаетесь с данным{' '}
      <a class="link" href="https://protocol.unisdk.easebar.com/release/latest_v486.html">
        пользовательским соглашением
      </a>
      ,{' '}
      <a class="link" href=" https://donate-gold.ru/terms-of-use">
        пользовательским соглашением Donate Gold
      </a>{' '}
      и нашей{' '}
      <a class="link" href="https://donate-gold.ru/privacy-policy">
        политикой конфиденциальности
      </a>
      .
    </>
  ),
  game17Meta: (
    <Helmet>
      <title>Донат в игру Dead by Daylight Mobile</title>
      <meta name="description" content="Как донатить в ДБД мобайл? Купить золотые клетки Dead by Daylight Mobile. Донат в дбд мобайл." />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),
  game18Name: 'Донат в Arena Breakout',
  game18BottomDesc: (
    <>
      Arena Breakout - командный хардкорный и реалистичный шутер от первого лица, разработанный компанией Tencent, который погрузит вас в новый мир, полный врагов и вооруженных конфликтов. Множество оружия на выбор и его кастомизация, вылазки за лутом, более 12 параметров персонажа и многое другое.
      <br />
      <br />
      <img src="/arena-breakout-about1.jpg" style={{ maxWidth: '100%' }} />
      <br />
      <br />
      <img src="/arena-breakout-about2.jpg" style={{ maxWidth: '100%' }} />
    </>
  ),
  game18Desc: <>У нас вы можете моментально задонатить себе облигации (бонды) в Arena Breakout. А так же купить боевые пропуски и кейсы.</>,
  game18Privacy: (
    <>
      Игровую валюту на данной странице вы донатите в пользу Tencent. Мы не являемся правообладателем игровой валюты Tencent, не определяем порядок её использования и функционирования в игре. Производя донат в игру Arena Breakout вы соглашаетесь с данным
      <a class="link" href="https://cdn.midasbuy.com/h5/overseah5/html/supplementary-terms-v2.html">
        пользовательским соглашением
      </a>
      ,{' '}
      <a class="link" href=" https://donate-gold.ru/terms-of-use">
        пользовательским соглашением Donate Gold
      </a>{' '}
      и нашей{' '}
      <a class="link" href="https://donate-gold.ru/privacy-policy">
        политикой конфиденциальности
      </a>
      .
    </>
  ),
  game18Meta: (
    <Helmet>
      <title>Донат в игру Arena Breakout</title>
      <meta name="description" content="Как донатить в Arena Breakout? Купить облигации (bonds) Arena Breakout. Боевой пропуск Arena Breakout" />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),
  game19Name: 'Донат в Honor of Kings',
  game19BottomDesc: (
    <>
      Honor of Kings - многопользовательская онлайн-игра в жанре 5v5 MOBA, разработанная TiMi Studio Group и выпущенная в Китае в 2015 году для платформ IOS и Android.
      <br />
      Honor of Kings является одной из популярных игр в жанре MOBA. Пользователь может выбрать любого из предложенных игрой персонажей, чтобы объединиться с другими игроками и участвовать в поединках 5v5. Каждый персонаж обладает уникальными способностями, которые могут быть полезны в бою.
      <br />
      <br />
      <img src="/hok-about.jpg" style={{ maxWidth: '100%' }} />
    </>
  ),
  game19Desc: <>У нас вы можете купить токены и задонатить их в Honor of Kings. Токены приходят моментально. Играйте и выигрывайте!</>,
  game19Privacy: (
    <>
      Игровую валюту на данной странице вы донатите в пользу TiMi Studio Group. Мы не являемся правообладателем игровой валюты TiMi Studio Group, не определяем порядок её использования и функционирования в игре. Производя донат в игру Honor of Kings вы соглашаетесь с данным{' '}
      <a class="link" href="https://cdn.midasbuy.com/oversea_web/static/terms.html">
        пользовательским соглашением
      </a>
      ,{' '}
      <a class="link" href=" https://donate-gold.ru/terms-of-use">
        пользовательским соглашением Donate Gold
      </a>{' '}
      и нашей{' '}
      <a class="link" href="https://donate-gold.ru/privacy-policy">
        политикой конфиденциальности
      </a>
      .
    </>
  ),
  game19Meta: (
    <Helmet>
      <title>Донат в игру Honor of Kings</title>
      <meta name="description" content="Как донатить в Honor of Kings? Купить токены Honor of Kings. Донат в Honor of Kings." />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),
  game20Name: 'Донат в Tower of Fantasy',
  game20BottomDesc: (
    <>
      Tower of Fantasy - кроссплатформенная игра в жанре Action/RPG с открытым миром, разработанная Hotta Studio и выпущенная для Китая в 2021 году, а после и для других стран в 2022 году. Сюжет повествует о будущем, в котором люди покидают землю, истощив ее ресурсы. Они поселяются на экзопланете
      Аида. Люди обнаруживают комету Мара, на которой находится большое количество энергии под названием Омниум, и, чтобы получить эту энергию, они строят башню Фантазии, но через пять лет после завершения строительства происходит взрыв энергии Омниума уничтожающий большую часть человечества.
      <br />
      <br />
      <img src="/tof-about.jpg" style={{ maxWidth: '100%' }} />
    </>
  ),
  game20Desc: <>У нас вы можете купить различные пропуски и таниум (Tatium) для Tower of Fantasy. Вместе в таниумом вам приходят темные кристаллы. Пополнение моментальное. Внимательно выбирайте сервер.</>,
  game20Privacy: (
    <>
      Игровую валюту на данной странице вы донатите в пользу Hotta Studio. Мы не являемся правообладателем игровой валюты Hotta Studio, не определяем порядок её использования и функционирования в игре. Производя донат в игру Tower of Fantasy вы соглашаетесь с данным{' '}
      <a class="link" href="https://www.toweroffantasy-global.com/UserAgreement.html">
        пользовательским соглашением
      </a>
      ,{' '}
      <a class="link" href=" https://donate-gold.ru/terms-of-use">
        пользовательским соглашением Donate Gold
      </a>{' '}
      и нашей{' '}
      <a class="link" href="https://donate-gold.ru/privacy-policy">
        политикой конфиденциальности
      </a>
      .
    </>
  ),
  game20Meta: (
    <Helmet>
      <title>Донат в игру Tower of Fantasy</title>
      <meta name="description" content="Как донатить в Tower of Fantasy? Купить таниум Tower of Fantasy. Боевой пропуск Tower of Fantasy. Донат в Tower of Fantasy. Купить tanium." />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),
  game21Name: 'Донат в Teamfight Tactics Mobile',
  game21BottomDesc: (
    <>
      Teamfight tactics - многопользовательская стратегическая игра в жанре автобаттлер, разработанная и выпущенная Riot Games для Windows и MacOC, а позже для Android и IOS. Пользователь, участвуя в карусели перед матчем, оказывается на поле с восьмью игроками, где должен выбрать персонажей,
      которых будет использовать в бою. Перед ним открывается остров с полем 4 на 7 клеток и ячейками для запасных персонажей. У каждого персонажа есть определенные навыки и принадлежность к кланам и классам, суммируя которые игрок получает бонусы.
      <br />
      <br />
      <img src="/teamfight-tactics-about.jpg" style={{ maxWidth: '100%' }} />
    </>
  ),
  game21Desc: <>У нас вы можете купить TFT монеты (коины) и задонатить их в Teamfight Tactics. В поле ввода RIOT ID вам нужно ввести логин и таг, в поле ввода есть пример. Пополнение игровой валюты мгновенное.</>,
  game21Privacy: (
    <>
      Игровую валюту на данной странице вы донатите в пользу Riot Games. Мы не являемся правообладателем игровой валюты Riot Games, не определяем порядок её использования и функционирования в игре. Производя донат в игру Teamfight Tactics Mobile вы соглашаетесь с данным{' '}
      <a class="link" href="https://www.riotgames.com/en/terms-of-service">
        пользовательским соглашением
      </a>
      ,{' '}
      <a class="link" href=" https://donate-gold.ru/terms-of-use">
        пользовательским соглашением Donate Gold
      </a>{' '}
      и нашей{' '}
      <a class="link" href="https://donate-gold.ru/privacy-policy">
        политикой конфиденциальности
      </a>
      .
    </>
  ),
  game21Meta: (
    <Helmet>
      <title>Донат в игру Teamfight Tactics Mobile</title>
      <meta name="description" content="Как донатить Teamfight Tactics Mobile? Купить TFT coins Teamfight Tactics Mobile. Донат в TFT." />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),
  game22Name: 'Донат в Blood Strike',
  game22BottomDesc: (
    <>
      Blood Strikes - мобильная игра в жанре Королевская битва, выпущенная Netease Games для Android устройств в 2023 году. Пользователю дается выбор между персонажами с уникальными навыками. Выбрав своего героя, игрок становится одним из сотни игроков на огромной карте мира. Используя свои
      стратегические навыки, храбрость и решимость, пользователь должен сокрушить своих врагов.
      <br />
      <br />
      <img src="/blood-strike-about.jpg" style={{ maxWidth: '100%' }} />
    </>
  ),
  game22Desc: <>У нас вы можете купить золото и задонатить его в Blood Strike. После ввода ID вам нужно подтвердить аккаунт, так вы увидите свой ник. Пополнение игровой валюты мгновенное.</>,
  game22Privacy: (
    <>
      Игровую валюту на данной странице вы донатите в пользу NetEase. Мы не являемся правообладателем игровой валюты NetEase, не определяем порядок её использования и функционирования в игре. Производя донат в игру Blood Strike вы соглашаетесь с данным{' '}
      <a class="link" href="https://protocol.unisdk.easebar.com/release/latest_v486.html">
        пользовательским соглашением
      </a>
      ,{' '}
      <a class="link" href=" https://donate-gold.ru/terms-of-use">
        пользовательским соглашением Donate Gold
      </a>{' '}
      и нашей{' '}
      <a class="link" href="https://donate-gold.ru/privacy-policy">
        политикой конфиденциальности
      </a>
      .
    </>
  ),
  game22Meta: (
    <Helmet>
      <title>Донат в игру Blood Strike</title>
      <meta name="description" content="Как донатить в Blood Strike? Купить золото Blood Strike. Донат блуд страйк." />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),
  game23Name: 'Донат в Farlight 84',
  game23BottomDesc: (
    <>
      Farlight 84 - мобильная игра в жанре «Королевская битва» с элементами геройского шутера, разработанная и выпущенная Miracle Games в 2023 году для IOS и Android. В игре вы можете разблокировать списки персонажей с уникальными навыками и выбирать любого из них, чтобы участвовать в борьбе на поле
      боя. Игрок может использовать реактивные ранцы для быстрых и внезапных атак или боевые машины для увеличения собственной мощи, а так же имеет несколько жизней в запасе.
      <br />
      <br />
      <img src="/farlight-84-about.jpg" style={{ maxWidth: '100%' }} />
    </>
  ),
  game23Desc: <>У нас вы можете купить алмазы (Diamonds) и задонатить их в Farlight 84. Пополнение игровой валюты мгновенное.</>,
  game23Privacy: (
    <>
      Игровую валюту на данной странице вы донатите в пользу Miracle Games. Мы не являемся правообладателем игровой валюты Miracle Games, не определяем порядок её использования и функционирования в игре. Производя донат в игру Farlight 84 вы соглашаетесь с данным{' '}
      <a class="link" href="https://www.farlightgames.com/termsofservice">
        пользовательским соглашением
      </a>
      ,{' '}
      <a class="link" href=" https://donate-gold.ru/terms-of-use">
        пользовательским соглашением Donate Gold
      </a>{' '}
      и нашей{' '}
      <a class="link" href="https://donate-gold.ru/privacy-policy">
        политикой конфиденциальности
      </a>
      .
    </>
  ),
  game23Meta: (
    <Helmet>
      <title>Донат в игру Farlight 84</title>
      <meta name="description" content="Как донатить в Farlight 84? Купить алмазы Farlight 84. Донат в Farlight 84." />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),
  game24Name: 'Донат в Eveechoes',
  game24BottomDesc: (
    <>
      EVE echoes - мобильная многопользовательская игра в жанре MMOG, разработанная CCP Games и выпущенная Netease в 2020 году. Спин-офф компьютерной игры EVE online. Игра представляет из себя космическую научно-фантастическую песочницу, где игрок управляет своим кораблем. Делится на бесплатный
      режим, в котором развитие персонажа и количество оборудования ограничено, а так же режим с месячной подпиской за внутриигровую валюту. Игрок получает возможность участвовать в битвах с другими игроками и монстрами, добывать ресурсы, улучшать своих персонажей. Перед пользователем открывается
      возможность создания собственных чертежей, кланов и альянсов, а так же присоединение к ним. Помимо этого в игре есть рынок, курируемый самими игроками.
      <br />
      <br />
      <img src="/eve-echoes-about.jpg" style={{ maxWidth: '100%' }} />
    </>
  ),
  game24Desc: <>У нас вы можете купить AUR задонатить их в Eveechoes. Сервер Аврора. Пополнение игровой валюты мгновенное.</>,
  game24Privacy: (
    <>
      Игровую валюту на данной странице вы донатите в пользу NetEase. Мы не являемся правообладателем игровой валюты NetEase, не определяем порядок её использования и функционирования в игре. Производя донат в игру Eveechoes вы соглашаетесь с данным{' '}
      <a class="link" href="https://protocol.unisdk.easebar.com/release/latest_v486.html">
        пользовательским соглашением
      </a>
      ,{' '}
      <a class="link" href=" https://donate-gold.ru/terms-of-use">
        пользовательским соглашением Donate Gold
      </a>{' '}
      и нашей{' '}
      <a class="link" href="https://donate-gold.ru/privacy-policy">
        политикой конфиденциальности
      </a>
      .
    </>
  ),
  game24Meta: (
    <Helmet>
      <title>Донат в игру Eveechoes</title>
      <meta name="description" content="Как донатить в Eveechoes? Купить AUR Eveechoes. Донат Eveechoes." />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),

  game25Name: 'Донат в игру Zenless Zone Zero',
  game25BottomDesc: (
    <>
      Zenless Zone Zero — ролевая компьютерная и мобильная игра в жанре городского фэнтезиaction-adventure, разработанная HoYoverse.
      <br />
      <br />
      События игры разворачиваются в постапокалиптическом футуристическом мегаполисе под названием Нью Эриду. В мир людей через Каверны проникли существа, именуемые Эфириалами. Эти Каверны нанесли огромный урон человечеству, практически уничтожив его. Однако, группе выживших удалось создать убежище,
      которое они назвали Нью Эриду. Освоив технологии Эфира, Нью Эриду начал быстро развиваться и превратился в лучший город на Земле! Игра отличается динамичным геймплеем, захватывающим сюжетом и высоким качеством графики.
      <br />
      <br />
      <a class="link" href="https://zenless.hoyoverse.com">
        Официальный сайт игры
      </a>
      <br />
      <br />
      <img src="/zenless-zone-zero-about.jpg" style={{ maxWidth: '100%' }} />
    </>
  ),
  game25Desc: <>Донат в Zenless Zone Zero по UID. Обращаем внимание, монохром приходят в игру в течение нескольких минут, время поступления зависит от загруженности сервера.</>,
  game25Privacy: (
    <>
      Игровую валюту на данной странице вы донатите в пользу HoYoverse (miHoYo). Мы не являемся правообладателем игровой валюты Zenless Zone Zero, не определяем порядок её использования и функционирования в игре. Производя донат в игру Zenless Zone Zero вы соглашаетесь с данным{' '}
      <a class="link" href="https://zenless.hoyoverse.com/ru-ru/company/privacy">
        {' '}
        пользовательским соглашением
      </a>
      ,{' '}
      <a class="link" href=" https://donate-gold.ru/terms-of-use">
        пользовательским соглашением Donate Gold
      </a>{' '}
      и нашей{' '}
      <a class="link" href="https://donate-gold.ru/privacy-policy">
        политикой конфиденциальности
      </a>
      .
    </>
  ),
  game25Meta: (
    <Helmet>
      <title>Донат в Zenless Zone Zero</title>
      <meta name="description" content="Как донатить в Zenless Zone Zero? Нужно указать UID и сервер. Донат приходит моментально. Донат в Zenless Zone Zero в 2025 году. Купить монохром." />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),
  game26Name: 'Донат в игру Marvel Rivals',
  game26BottomDesc: (
    <>
      Marvel Rivals — геройский командный PVP-шутер 6х6 от третьего лица по мотивам кинокомиксов MARVEL, изданный разработчиком NetEase Games в сотрудничестве с Marvel Games.
      <br />
      <br />
      При правильном сочетании двух или трёх персонажей игроки могут использовать «Динамическую синергию героев», которая ещё больше повышает боевую эффективность их игрового персонажа. В игре также есть разрушаемое окружение, позволяющее игрокам изменять поле боя (под наблюдением Галакты) в своих интересах.
      <br />
      <br />
      <img src="/marvel-rivals-about.jpg" style={{ maxWidth: '100%' }} />
    </>
  ),
  game26Desc: <>Донат в Marvel Rivals по ID. Покупайте Marvel Rivals и побеждайте противников! Игровая валюта Lattice поступит на ваш аккаунт очень быстро.</>,
  game26Privacy: (
    <>
      Игровую валюту на данной странице вы донатите в пользу NetEase Games. Мы не являемся правообладателем игровой валюты Marvel Rivals, не определяем порядок её использования и функционирования в игре. Производя донат в игру Marvel Rivals вы соглашаетесь с данным{' '}
      <a class="link" href="https://unisdk.update.netease.com/html/latest_v30.html">
        {' '}
        пользовательским соглашением
      </a>
      ,{' '}
      <a class="link" href="https://donate-gold.ru/terms-of-use">
        пользовательским соглашением Donate Gold
      </a>{' '}
      и нашей{' '}
      <a class="link" href="https://donate-gold.ru/privacy-policy">
        политикой конфиденциальности
      </a>
      .
    </>
  ),
  game26Meta: (
    <Helmet>
      <title>Донат в Marvel Rivals</title>
      <meta name="description" content="Как донатить в Marvel Rivals? Купить дешево Lattice. Донат в Marvel Rivals в 2025 году. Донат Lattice." />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),
  game27Name: 'Донат в игру Arena Breakout: Infinite',
  game27BottomDesc: (
    <>
       Arena Breakout: Infinite - командный хардкорный и реалистичный шутер от первого лица, разработанный компанией Tencent для PC. Игра погрузит вас в новый мир, полный врагов и вооруженных конфликтов. Множество оружия на выбор и его кастомизация, вылазки за лутом, более 12 параметров персонажа и многое другое.
      <br />
      <br />
      События игры разворачиваются в постапокалиптическом футуристическом мегаполисе под названием Нью Эриду. В мир людей через Каверны проникли существа, именуемые Эфириалами. Эти Каверны нанесли огромный урон человечеству, практически уничтожив его. Однако, группе выживших удалось создать убежище, которое они назвали Нью Эриду. Освоив технологии Эфира, Нью Эриду начал быстро развиваться и превратился в лучший город на Земле! Игра отличается динамичным геймплеем, захватывающим сюжетом и высоким качеством графики.
      <br />
      <br />
      <img src="/arena-breakout-infinite-about.jpg" style={{ maxWidth: '100%' }} />
    </>
  ),
  game27Desc: <>У нас вы можете моментально задонатить себе облигации (бонды) в Arena Breakout: Infinite.</>,
  game27Privacy: (
    <>
      Игровую валюту на данной странице вы донатите в пользу Tencent. Мы не являемся правообладателем игровой валюты Tencent, не определяем порядок её использования и функционирования в игре. Производя донат в игру Arena Breakout вы соглашаетесь с данным
      <a class="link" href="https://cdn.midasbuy.com/h5/overseah5/html/supplementary-terms-v2.html">
        пользовательским соглашением
      </a>
      ,{' '}
      <a class="link" href=" https://donate-gold.ru/terms-of-use">
        пользовательским соглашением Donate Gold
      </a>{' '}
      и нашей{' '}
      <a class="link" href="https://donate-gold.ru/privacy-policy">
        политикой конфиденциальности
      </a>
      .
    </>
  ),
  game27Meta: (
     <Helmet>
      <title>Донат в игру Arena Breakout: Infinite</title>
      <meta name="description" content="Как донатить в Arena Breakout: Infinite? Купить облигации (bonds) Arena Breakout. Bonds донат" />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),
  game28Name: 'Донат в игру Magic Chess: Go Go',
  game28BottomDesc: (
    <>
       Magic Chess: Go Go - это отдельная многопользовательская онлайн-стратегия с автоматическими боями, отдельно выделенная из Mobile Legends: Bang Bang.
      <br />
      <br />
      Выбирайте своего командира и собирайте юнитов, чтобы одержать победу над противником! 
      <br />
      <br />
      <img src="/magic-chess-go-go-about.jpg" style={{ maxWidth: '100%' }} />
    </>
  ),
  game28Desc: <>У нас вы можете моментально задонатить себе алмазы в Magic Chess: Go Go.</>,
  game28Privacy: (
    <>
      Игровую валюту на данной странице вы донатите в пользу Moonton. Мы не являемся правообладателем игровой валюты Moonton, не определяем порядок её использования и функционирования в игре. Производя донат в игру Magic Chess: Go Go вы соглашаетесь с данным
      <a class="link" href="https://play.mc-gogo.com/register/agreement">
        пользовательским соглашением
      </a>
      ,{' '}
      <a class="link" href=" https://donate-gold.ru/terms-of-use">
        пользовательским соглашением Donate Gold
      </a>{' '}
      и нашей{' '}
      <a class="link" href="https://donate-gold.ru/privacy-policy">
        политикой конфиденциальности
      </a>
      .
    </>
  ),
  game28Meta: (
     <Helmet>
      <title>Донат в игру Magic Chess: Go Go</title>
      <meta name="description" content="Как донатить в Magic Chess: Go Go? Купить алмазы  Magic Chess: Go Go." />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),
  homePage: (
    <Helmet>
      <title>Главная страница</title>
      <meta name="description" content="Donate Gold — сервис, позволяющий вам самостоятельно донатить в мобильные и компьютерные игры. Как донатить в игру? Есть ответ!" />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),
  accountPage: (
    <Helmet>
      <title>Список игр</title>
      <meta name="description" content="Игры в которые вы можете сделать донат" />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),
  authPage: (
    <Helmet>
      <title>Авторизация</title>
      <meta name="description" content="Войти в аккаунт" />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),
  aboutPage: (
    <Helmet>
      <title>О сервисе</title>
      <meta name="description" content="Сервис позволяет донатить в игры" />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),
  donatePage: (
    <Helmet>
      <title>Инструкции как донатить</title>
      <meta name="description" content="Прочитайте и посмотрите внимательно как донатить в игры" />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),
  rulesPage: (
    <Helmet>
      <title>Правила использования сервиса</title>
      <meta name="description" content="Документация на сайте" />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),
  supportPage: (
    <Helmet>
      <title>Поддержка</title>
      <meta name="description" content="Контакты поддержки" />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),
  termsOfUsePage: (
    <Helmet>
      <title>Пользовательское соглашение</title>
      <meta name="description" content="Документация на сайте" />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),
  privacyPolicyPage: (
    <Helmet>
      <title>Политика конфиденциальности</title>
      <meta name="description" content="Документация на сайте" />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),
  faqPage: (
    <Helmet>
      <title>Вопросы и ответы</title>
      <meta name="description" content="Ответы на часто задаваемые вопросы" />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),
  reviewPage: (
    <Helmet>
      <title>Отзывы наших пользователей</title>
      <meta name="description" content="Отзывы о Donate Gold" />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),
  guarantePage: (
    <Helmet>
      <title>Гарантии</title>
      <meta name="description" content="Наши гарантии" />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),
  returnPolicyPage: (
    <Helmet>
      <title>Правила возврата</title>
      <meta name="description" content="Правила возврата платежей" />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),
  profilePage: (
    <Helmet>
      <title>Профиль пользователя</title>
      <meta name="description" content="Личный кабинет" />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),
  cartPage: (
    <Helmet>
      <title>Корзина</title>
      <meta name="description" content="Корзина" />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),
  articlePage: (
    <Helmet>
      <title>Статьи</title>
      <meta name="description" content="Статьи" />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),
  ratingPage: (
    <Helmet>
      <title>Рейтинг</title>
      <meta name="description" content="Рейтинг" />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),
};
