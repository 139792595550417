import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import ExitIcon from '@mui/icons-material/ExitToApp';
import AccountIcon from '@mui/icons-material/AccountCircle';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { purple, grey } from '@mui/material/colors';
import { shadows } from '@mui/system';
import { Badge, Container, Fab, Menu, MenuItem, Paper, TextField, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import AddCardIcon from '@mui/icons-material/AddCard';
import BuyModal from './BuyModal';
import SuccessModal from './SuccessModal';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, sendPackageInChat, setCart, setUpdateCartEmpty } from '../redux/slices/user.slice';
import { currencyFormat } from '../utils/currencyFormat';
import PaymentModal from './PaymentModal';
import CloseIcon from '@mui/icons-material/Close';
import ErrorModal from './ErrorModa';
import Loading from './Loading';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { createTransactionReset, getTransactions } from '../redux/slices/transaction.slice';
import Footer from './Footer';
import { getPackage } from '../redux/slices/package.slice';
import { getPayments } from '../redux/slices/payment.slice';
import { Navigate, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import PaymentCardModal from './PaymentCardModal';
import GameButton from './GameButton';
import BonusMenu from './BonusMenu';
import axios from 'axios';
import GradeOutlinedIcon from '@mui/icons-material/GradeOutlined';
import moment from 'moment';
import { AccountBalanceOutlined, AccountBalanceWalletOutlined, ChatBubbleOutline, FavoriteOutlined, PhotoCamera, RateReviewOutlined, ShoppingCartOutlined, SportsEsportsOutlined, GradeOutlined, SupportAgentOutlined, Person } from '@mui/icons-material';
import Snowfall from 'react-snowfall';
import { locales } from '../locales';
import MenuBtn from './MenuBtn';
import TopHeader from './TopHeader';
import { getTypePaymentList } from '../redux/slices/app.slice';
import { calcPrice } from '../utils/calcPrice';
import Loader from './Loader';
import { openSteam } from './HomeLayout';
const drawerWidth = 240;

const navItems = ['Home', 'About', 'Contact'];

function DrawerAppBar(props) {
  const { window, isFull, isWhite } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [supportCount, setSupportCount] = useState(0);
  const [mobileOpenSucc, setMobileOpenSucc] = React.useState(false);
  // const [openSucc, setOpenSucc] = React.useState(false);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [openError, setOpenError] = React.useState(false);
  // const [errorText, setErrorText] = React.useState(false);
  const handleClosePaymentCardModal = () => {
    setOpenPaymentModal(false);
  };

  const dispatch = useDispatch();
  const {
    getUserState: { loading, data, error },
    cart,
    updateCartEmpty,
    getBonusState: { loading: loadingBonus, data: dataBonus, error: dataError },
  } = useSelector((state) => state.user);
  const {
    getCreditCardState: { error: errorCreditCard },
  } = useSelector((state) => state.creditCard);
  const {
    getPackageState: { data: dataPackages, error: errorGetPackage },
  } = useSelector((state) => state.package);
  const {
    createTransactionState: { error: errorCreateTransaction },
    getTransactionsState: { error: errorGetTransactions },
  } = useSelector((state) => state.transaction);
  const {
    getPaymentsState: { error: errorgetPayments },
  } = useSelector((state) => state.payment);
  React.useEffect(() => {
    if (!loading && data && !error) {
      dispatch(getPackage());
      dispatch(getTransactions());
      dispatch(getPayments());
      dispatch(getTypePaymentList());
    }
  }, []);
  useEffect(() => {
    console.log(window);
    const cartLocal = JSON.parse(localStorage.getItem('cart'));
    const cartData = [];

    if (dataPackages && cartLocal instanceof Array) {
      cartLocal?.map((cartItem) => {
        const findPackage = dataPackages?.find((packageItem) => packageItem?.id === cartItem?.packageId);
        if (findPackage && parseInt(cartItem?.count) > 0) {
          cartData?.push({
            packageId: findPackage?.id,
            price: calcPrice({ isBoost: cartItem?.isBoost, priceMoogold: findPackage?.priceMoogold, priceNEcards: findPackage?.priceNEcards, discount: findPackage?.discount }),
            count: cartItem?.count,
            name: findPackage?.name,
          });
        } else if (cartItem?.packageId === 0 && parseInt(cartItem?.count) > 0) {
          cartData?.push({
            packageId: 0,
            price: 1,
            count: cartItem?.count,
            name: 'Пополнение счета',
          });
        }
      });
      dispatch(setCart(cartData));
    } else {
      setOpenPaymentModal(false);
      dispatch(setCart([]));
    }
  }, [dataPackages, updateCartEmpty]);

  const navigate = useNavigate();
  React.useEffect(() => {
    if (errorCreditCard?.error === 'PROBLEM_WITH_TOKEN' || errorGetPackage?.error === 'PROBLEM_WITH_TOKEN' || errorCreateTransaction?.error === 'PROBLEM_WITH_TOKEN' || errorGetTransactions?.error === 'PROBLEM_WITH_TOKEN' || errorgetPayments?.error === 'PROBLEM_WITH_TOKEN') {
      dispatch(getUser());
    }
  }, [errorCreditCard, errorGetPackage, errorCreateTransaction, errorGetTransactions]);
  const dispath = useDispatch();

  const {
    createTransactionState: { error: transError, loading: transLoading, data: transData },
  } = useSelector((state) => state.transaction);

  const {
    getUserState: { data: user },
    addedCart,
  } = useSelector((state) => state.user);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  // const handleClickOpenSucc = () => {
  //   setOpenSucc(true);
  // };

  // const handleCloseSucc = (value) => {
  //   setOpenSucc(false);
  //   dispath(getUser());
  // };

  const handleCloseError = (value) => {
    setOpenError(false);
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // React.useEffect(() => {
  //   if (transData) {
  //     setOpenSucc(transData);
  //     dispath(createTransactionReset());
  //   }
  // }, [transData]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const openMenu = Boolean(anchorEl);
  const [settingsData, setSettingsData] = useState(null);
  const [showWarning, setShowWarning] = useState(false);
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_SERVER_URL}/settings`).then((resp) => {
      setSettingsData(resp.data);
    });
  }, []);

  useEffect(() => {
    if (settingsData) {
      if (settingsData?.[0]?.activeWarning) {
        const warningDate = localStorage.getItem('warningTime');
        const warningDateMoment = moment(warningDate);
        if (warningDateMoment.isValid()) {
          if (moment().isAfter(warningDateMoment)) {
            setShowWarning(true);
          }
        } else {
          setShowWarning(true);
        }
      }
    }
  }, [settingsData]);
  // React.useEffect(() => {
  //   if (transError?.error === 'PACKAGE_NOT_ACTIVE') {
  //     dispatch(sendPackageInChat({ packageId: transError?.package?.id, type: 'package-over' }));
  //     dispath(createTransactionReset());
  //   } else if (transError) {
  //     const err =
  //       transError?.error === 'PACKAGE_NOT_ACTIVE' ? (
  //         <Box sx={{ fontSize: { mobile: '20px', xs: '16px' } }}>{locales[process.env.REACT_APP_LOCALE].packageNotAvailable}</Box>
  //       ) : transError?.error === 'BALANCE_ERROR' ? (
  //         locales[process.env.REACT_APP_LOCALE].insufficientFunds
  //       ) : transError?.error === 'ACCOUNT_NOT_FOUND' ? (
  //         <div>{locales[process.env.REACT_APP_LOCALE].accountNotFound}</div>
  //       ) : (
  //         locales[process.env.REACT_APP_LOCALE].unexpectedError
  //       );
  //     setErrorText(err);
  //     setOpenError(true);
  //     dispath(createTransactionReset());
  //   }
  // }, [transError]);

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        MUI
      </Typography>
      <Divider />
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;
  const [showCart, setShowCart] = useState(false);
  const handleCloseCart = () => {
    setShowCart(false);
  };
  const handleOpenCart = () => {
    setShowCart(true);
  };
  console.log(process.env.REACT_APP_LOCALE);
  const [openPay, setOpenPay] = useState(false);
  const handleClosePay = () => {
    setOpenPay(false);
  };
  return !loading && data && !error ? (
    <>
      <Paper
        sx={{
          ...(isWhite && { backgroundColor: '#fff' }),
          borderRadius: 0,
          height: '100%',
          overflow: 'scroll',
          '&::-webkit-scrollbar': {
            display: 'none !important',
            scrollbarWidth: 'none',
            scrollbarHeight: 'none',
          },
        }}>
        <Box sx={{ display: 'flex', height: '70%' }}>
          <AppBar component="nav" sx={{ boxShadow: 0, backgroundColor: '#060606' }}>
            {' '}
            {/* <Snowfall snowflakeCount={50} speed={[0, 0.5]} radius={[0.5, 1]} wind={[0, 0.1]} />  */}
            <TopHeader />
            <Toolbar variant="dense" sx={{ minHeight: { xs: '59px', mobile: '85px' } }}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {' '}
                  <Box sx={{ display: { mobile: 'none', xs: 'block' } }}>
                    <Button id="basic-button" aria-controls={openMenu ? 'basic-menu' : undefined} aria-haspopup="true" aria-expanded={openMenu ? 'true' : undefined} onClick={handleClickMenu} sx={{ px: '0', minWidth: '30px' }}>
                      <MenuIcon sx={{ fontSize: '30px' }} />
                    </Button>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={openMenu}
                      onClose={handleCloseMenu}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}>
                      <MenuItem onClick={() => navigate('/profile')}>Личный кабинет</MenuItem>
                      <MenuItem onClick={() => navigate('/')}>{locales[process.env.REACT_APP_LOCALE].main}</MenuItem>
                      <MenuItem onClick={() => navigate('/account')}>{locales[process.env.REACT_APP_LOCALE].games}</MenuItem>
                      {process.env.REACT_APP_LOCALE != 'en' && <MenuItem onClick={() => navigate('/rating')}>{locales[process.env.REACT_APP_LOCALE].rating}</MenuItem>}
                      {process.env.REACT_APP_LOCALE != 'en' && <MenuItem onClick={() => navigate('/reviews')}> {locales[process.env.REACT_APP_LOCALE].reviews}</MenuItem>}
                      <MenuItem onClick={() => navigate('/support')}>{locales[process.env.REACT_APP_LOCALE].support}</MenuItem>
                    </Menu>
                  </Box>
                  <Box sx={{ display: { xs: 'none', mobile: 'block' } }}>
                    <Link to="/">
                      <img src="/logo.png" style={{ height: '70px', width: '90px', objectFit: 'cover', display: 'block' }} />
                    </Link>
                  </Box>
                </Box>
                {/* <Box sx={{ display: { mobile: 'flex', xs: 'none' }, justifyContent: 'center', alignItems: 'center', mr: 1 }}>
                  <Button disableRipple={true} onClick={() => navigate('/about')} sx={{ textTransform: 'none', '&:hover': { backgroundColor: 'transparent' }, my: 2, color: 'white', display: 'block' }}>
                    {locales[process.env.REACT_APP_LOCALE].aboutService}
                  </Button>
                  {process.env.REACT_APP_LOCALE != 'en' && (
                    <Button
                      startIcon={<StarBorderOutlinedIcon sx={{ color: 'rgb(255, 215, 0)', marginRight: '-4px' }} />}
                      disableRipple={true}
                      onClick={() => navigate('/rating')}
                      sx={{ textTransform: 'none', '&:hover': { backgroundColor: 'transparent' }, my: 2, color: 'white', display: 'flex', alignItems: 'center' }}>
                      {locales[process.env.REACT_APP_LOCALE].rating}
                    </Button>
                  )}
                  <Button disableRipple={true} onClick={() => navigate('/donate')} sx={{ textTransform: 'none', '&:hover': { backgroundColor: 'transparent' }, my: 2, color: 'white', display: 'block' }}>
                    {locales[process.env.REACT_APP_LOCALE].instruction}
                  </Button>{' '}
                  <Button disableRipple={true} onClick={() => navigate('/faq')} sx={{ textTransform: 'none', '&:hover': { backgroundColor: 'transparent' }, my: 2, color: 'white', display: 'block', minWidth: 0 }}>
                    {locales[process.env.REACT_APP_LOCALE].faq}
                  </Button>
                  {process.env.REACT_APP_LOCALE != 'en' && (
                    <Button disableRipple={true} onClick={() => navigate('/reviews')} sx={{ textTransform: 'none', '&:hover': { backgroundColor: 'transparent' }, my: 2, color: 'white', display: 'block' }}>
                      {locales[process.env.REACT_APP_LOCALE].reviews}
                    </Button>
                  )}
                  <Button disableRipple={true} onClick={() => navigate('/guarante')} sx={{ textTransform: 'none', '&:hover': { backgroundColor: 'transparent' }, my: 2, color: 'white', display: 'block' }}>
                    {locales[process.env.REACT_APP_LOCALE].guarantee}
                  </Button>
                  <Button disableRipple={true} onClick={() => navigate('/support')} sx={{ textTransform: 'none', '&:hover': { backgroundColor: 'transparent' }, my: 2, color: 'white', display: 'block' }}>
                    {locales[process.env.REACT_APP_LOCALE].support}
                  </Button>
                </Box> */}
                <Box sx={{ display: 'flex', alignItems: 'center', height: '100%', columnGap: { xs: '15px', mobile: '30px' } }}>
                  <MenuBtn
                    customStyle={{
                      alignItems: 'center',
                      justifyContent: 'center',
                      display: { mobile: 'none', xs: 'flex' },
                      background: '#1b2838',
                    }}
                    tooltip={'Пополнение Steam'}
                    onClick={() => {
                      openSteam();
                    }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24 24" style={{ transform: 'scale(1.18)' }}>
                      <path
                        fill="currentColor"
                        d="M12 2a10 10 0 0 1 10 10a10 10 0 0 1-10 10c-4.6 0-8.45-3.08-9.64-7.27l3.83 1.58a2.84 2.84 0 0 0 2.78 2.27c1.56 0 2.83-1.27 2.83-2.83v-.13l3.4-2.43h.08c2.08 0 3.77-1.69 3.77-3.77s-1.69-3.77-3.77-3.77s-3.78 1.69-3.78 3.77v.05l-2.37 3.46l-.16-.01c-.59 0-1.14.18-1.59.49L2 11.2C2.43 6.05 6.73 2 12 2M8.28 17.17c.8.33 1.72-.04 2.05-.84s-.05-1.71-.83-2.04l-1.28-.53c.49-.18 1.04-.19 1.56.03c.53.21.94.62 1.15 1.15c.22.52.22 1.1 0 1.62c-.43 1.08-1.7 1.6-2.78 1.15c-.5-.21-.88-.59-1.09-1.04zm9.52-7.75c0 1.39-1.13 2.52-2.52 2.52a2.52 2.52 0 0 1-2.51-2.52a2.5 2.5 0 0 1 2.51-2.51a2.52 2.52 0 0 1 2.52 2.51m-4.4 0c0 1.04.84 1.89 1.89 1.89c1.04 0 1.88-.85 1.88-1.89s-.84-1.89-1.88-1.89c-1.05 0-1.89.85-1.89 1.89"
                      />
                    </svg>
                  </MenuBtn>
                  <MenuBtn
                    customStyle={{
                      display: { mobile: 'none', xs: 'block' },
                      background: 'linear-gradient(180deg, rgba(219,70,190,1) 0%, rgba(246,98,48,1) 49%, rgba(251,206,29,1) 100%)',
                    }}
                    tooltip={'Игры'}
                    onClick={() => {
                      navigate('/account');
                    }}>
                    <SportsEsportsOutlined sx={{ fontSize: '24px' }} />
                  </MenuBtn>
                  <GameButton
                    style={{
                      marginRight: '-20px',
                      background: '#1b2838',
                      color: '#fff',

                      '&:hover': {
                        background: '#171a21',
                      },
                    }}
                    className="bubbly-button-steam"
                    text="Пополнение Steam"
                    onClick={() => {
                      openSteam();
                    }}
                  />
                  <GameButton
                    text="Донат в игры"
                    onClick={() => {
                      navigate('/account');
                    }}
                  />
                  <div style={{ height: '34px', width: '1px', backgroundColor: '#333' }}></div>
                  <Tooltip title={'Пополнить баланс'} placement="bottom">
                    <Box
                      sx={{ cursor: 'pointer', userSelect: 'none', display: 'flex', alignItems: 'center', height: '35px', border: '1px solid #222222', backgroundColor: '#060606', borderRadius: '6px', padding: '0 14px' }}
                      onClick={() => {
                        setOpenPay(true);
                      }}>
                      <AccountBalanceWalletOutlined sx={{ fontSize: '24px', marginRight: '10px' }} />
                      <Box sx={{ whiteSpace: 'nowrap', display: 'block', fontWeight: '400 !important', color: 'success.light' }}>{currencyFormat(user.balance)}</Box>
                    </Box>
                  </Tooltip>

                  <MenuBtn tooltip={'Корзина'}>
                    <div class={addedCart && 'cart-moving'}>
                      <Badge
                        onClick={() => {
                          navigate('/cart');
                        }}
                        badgeContent={cart?.length || 0}
                        color="primary"
                        sx={{
                          cursor: 'pointer',
                          '& .MuiBadge-badge': { color: '#e7e1d8', backgroundColor: '#9c2628', fontWeight: '600 !important' },
                          '& svg': {
                            fill: '#fff',
                          },
                        }}>
                        <ShoppingCartOutlined sx={{ fontSize: '24px' }} />
                      </Badge>
                    </div>
                  </MenuBtn>
                  {/* <Box
                    sx={{ cursor: 'pointer', display: { mobile: 'block', xs: 'none' } }}
                    onClick={() => {
                      navigate('/profile');
                    }}>
                    {data?.avatar ? (
                      <img style={{ width: '28px', height: '28px', objectFit: 'cover', borderRadius: '50%' }} src={`${process.env.REACT_APP_SERVER_DOMAIN}/images/${data?.avatar}`} />
                    ) : (
                      <Box sx={{ transition: 'all 0.3s', position: 'relative', width: '28px', height: '28px', borderRadius: '50%', border: `2px solid rgba(255, 255, 255,0.3)` }}>
                        <Person sx={{ fontSize: '20px', color: '#666', top: '50%', left: '50%', transform: 'translate(-50%,-50%)', position: 'absolute', color: 'rgba(255, 255, 255,0.3)' }} />
                      </Box>
                    )}
                  </Box> */}
                </Box>
              </Box>

              <div variant="h6" component="div" style={{ flexGrow: 1 }}></div>
            </Toolbar>
          </AppBar>
          <Box component="nav">
            <Drawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: 'block', sm: 'none' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
              }}>
              {drawer}
            </Drawer>
          </Box>
          <Box
            component="main"
            sx={{
              width: '100%',
              paddingTop: { xs: '59px', mobile: 'var(--header-height)' },
              height: '100%',
            }}>
            <div style={{ ...(isWhite && { backgroundColor: '#fff' }) }} sx={{ height: '100%', ...(isFull && { maxWidth: 'none', padding: 0 }) }}>
              <div className="" style={{ display: 'grid', gridTemplateRows: '1fr auto', height: 'calc(100vh - 65px' }}>
                <Container style={{ ...(isFull && { maxWidth: 'none', padding: 0 }), ...(isWhite && { backgroundColor: '#fff' }) }}> {props.children}</Container>
                <Footer />
                {/* <Fab
                  onClick={handleClickOpen}
                  color="warning"
                  aria-label="add"
                  sx={{
                    position: 'fixed',
                    right: '20px',
                    bottom: '20px',
                  }}>
                  <AddIcon />
                </Fab> */}
              </div>
            </div>
            <BuyModal open={open} onClose={handleClose} />
            {/* {transLoading && <Loading />} */}
            {/* <ErrorModal open={openError} text={errorText} onClose={handleCloseError} /> */}
            {/* <SuccessModal open={openSucc} onClose={handleCloseSucc} text={undefined} /> */}

            <PaymentCardModal open={openPaymentModal} onClose={handleClosePaymentCardModal} supportCount={supportCount} />
            <PaymentModal open={openPay} typePay={1} onClose={handleClosePay} />
          </Box>

          {showWarning && (
            <Box sx={{ position: 'fixed', top: 0, left: 0, right: 0, background: '#555', padding: '30px', width: '100vw', zIndex: '1000000', color: 'white', textAlign: 'center', boxSizing: 'border-box' }}>
              <Box sx={{ maxWidth: '80%', margin: '0 auto', textAlign: 'center', lineHeight: '24px' }}>{settingsData?.[0]?.textWarning}</Box>
              <IconButton
                onClick={() => {
                  setShowWarning(false);
                  localStorage.setItem('warningTime', moment().add(3, 'hours').toDate());
                }}
                disableRipple
                sx={{ position: 'fixed', right: '10px', top: '5px' }}>
                <CloseIcon />
              </IconButton>
            </Box>
          )}
        </Box>{' '}
        {loadingBonus && <Loader />}
      </Paper>
    </>
  ) : (
    <Navigate to="/auth" />
  );
}

export default DrawerAppBar;
